.stacked-bar-graph {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // height: 26px;
    color: #414042;
    z-index: 1;

    span {
        position: relative;
        display: inline-block;
        flex: 1 1 100px;
        max-width: 100%;
        height: 100%;
        float: left;
        color: $white;
        font-size: .85rem;
        line-height: 1.28;
        border: 1px solid rgba($white, .4);
        border-left-width: 0;
        border-right-width: 0;
        padding: .25rem 0 .25rem 1.5rem;
        white-space: nowrap;
        // overflow: hidden; // to be able to make the arrows work
        text-overflow: ellipsis;

        &:after {
            content: "";
            position: absolute;
            display: inline-block;
            width: 25px;
            height: 25px;
            top: 0;
            right: -12.5px;
            transform: scale(0.707) rotate(45deg);
            box-shadow: 2px -2px 1px rgba($white, .5),
                        3px -3px rgba($white, .25);
            z-index: 1;
        }

        &:first-of-type {
            padding-left: .5rem;
            border-top-left-radius: .75rem;
            border-bottom-left-radius: .75rem;
            border-left-width: 1px;
        }

        &:last-of-type {
            border-top-right-radius: .75rem;
            border-bottom-right-radius: .75rem;
            border-right-width: 0;
            border-right-width: 1px;

            &:after {
                content: none;
            }
        }

        &.bg-success {
            &:after {
                background-color: $success;
            }
        }
        &.bg-info {
            &:after {
                background-color: $info;
            }
        }
        &.bg-warning {
            &:after {
                background-color: $warning;
            }
        }
        &.bg-danger {
            &:after {
                background-color: $danger;
            }
        }
        &.bg-gray-300 {
            &:after {
                background-color: $gray-300;
                box-shadow: 2px -2px 1px rgba($black, .1),
                            3px -3px rgba($black, .05);
            }
        }
    }

    .bg-gray-300 {
        border-color: $gray-400;
        border-style: dotted;
        color: $gray-600;
    }

}