// .customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper {
	width: 100%;
}

// .customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
	text-align: center;
	width: 100%;
}

.react-datepicker * {
    outline: none !important;
}

.react-datepicker-wrapper {
	width: inherit;
}

.react-datepicker-popper {
	z-index: 999;
}

.react-datepicker__time-list {
	overflow-y: auto !important;
}

.react-datepicker__time-list-item {
	margin-right: 1px !important;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
    background-color: #2a87d0 !important;
}

.react-datepicker__time-list-item--disabled {
	display: none;
}

.react-datepicker__time-list-item--hidden {
	display: none !important;
}

.react-datepicker__time-list-item--selected {
	display: block;
}

.react-datepicker__input-container {
	display: block;
}

.react-table-layout {
	margin: 12px;
}
.react-bootstrap-table table {
	table-layout: auto;
}

.react-bootstrap-table-page-btns-ul{
	justify-content: flex-end;
}

#pageDropDown{
	background-color: #fff;
	// border: 1px solid #adb5bd;
	color: #495057
}


.fullScreenLoader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba( 255, 255, 255, .3);
}



// React Datepicker custom theme

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	color: theme-color("dark") !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-bottom-color: $gray-500 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-bottom-color: $gray-100 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-bottom-color: $gray-500 !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-top-color: white !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-top-color: $gray-500 !important;
}

.react-datepicker {
	font-weight: 400;
	color: theme-color("dark") !important;
	border-color: $gray-500 !important;
	border-radius: 0.25rem !important;
	//overflow: hidden;

	* {
		outline: none !important;
		user-select: none;
	}
}

.react-datepicker__header {
	background-color: $gray-100 !important;
	border-bottom-color: $gray-500 !important;
}

.react-datepicker__navigation {
}

.react-datepicker__navigation--previous {
	border-right-color: theme-color("primary") !important;
}

.react-datepicker__navigation--previous:hover {
	border-right-color: theme-color("secondary") !important;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
	border-right-color: theme-color("light") !important;
}

.react-datepicker__navigation--next {
	border-left-color: theme-color("primary") !important;
}

.react-datepicker__navigation--next:hover {
	border-left-color: theme-color("secondary") !important;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
	border-left-color: theme-color("light") !important;
}

.react-datepicker__navigation--years-previous {
	border-top-color: theme-color("primary") !important;
}

.react-datepicker__navigation--years-previous:hover {
	border-top-color: theme-color("secondary") !important;
}

.react-datepicker__navigation--years-upcoming {
	border-bottom-color: theme-color("primary") !important;
}

.react-datepicker__navigation--years-upcoming:hover {
	border-bottom-color: theme-color("secondary") !important;
}

.react-datepicker__time-container {
	border-left-color: $gray-500 !important;
}
.react-datepicker__time-container--with-today-button {
	right: -85px !important;
	border-top: none !important;
	border-right: none !important;
	border-bottom: none !important;
	border-radius: 0 0.5rem 0.5rem 0 !important;
	box-shadow: 0 2px 10px rgba($black, .1) !important;
}

.react-datepicker__time-container .react-datepicker__time {
	background: white !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
	height: auto !important;
	padding: 10px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
	background-color: theme-color("light") !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
	background-color: theme-color("primary") !important;
	color: white !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
	background-color: theme-color("primary") !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
	color: theme-color("light") !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
	background-color: transparent !important;
}

.react-datepicker__week-number {
	color: theme-color("light") !important;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
	border-radius: 0.3rem !important;
	background-color: theme-color("light") !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: theme-color("dark") !important;
}

.react-datepicker__header,
.react-datepicker-time__header,
.react-datepicker__day-name {
	user-select: none;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
	border-radius: 0.3rem !important;
	background-color: theme-color("primary") !important;
	color: #fff !important;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
	background-color: theme-color("primary") !important;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
	color: theme-color("light") !important;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
	background-color: transparent !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	border-radius: 10rem !important;
	background-color: $gray-200 !important;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
	border-radius: 10rem !important;
	background-color: theme-color("primary") !important;
	color: #fff !important;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
	background-color: lighten(theme-color("primary"), .2) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	border-radius: 10rem !important;
	background-color: darken(theme-color("primary"), .2) !important;
	color: #fff !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: theme-color("primary") !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	border-radius: 10rem !important;
	background-color: darken(theme-color("primary"), .3) !important;
	color: #fff !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
	background-color: theme-color("primary") !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
	background-color: rgba(33, 107, 165, 0.5) !important;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__month--selecting-range,
.react-datepicker__year-text--in-range {
	background-color: theme-color("light") !important;
	color: theme-color("dark") !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	color: $gray-300 !important;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
	background-color: transparent !important;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
	background-color: theme-color("primary") !important;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
	background-color: theme-color("light") !important;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	border: 1px solid transparent !important;
	border-radius: 0.3rem !important;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
	border-top-color: theme-color("primary") !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	border-top-color: theme-color("light") !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: theme-color("light") !important;
	border-radius: 0.3rem !important;
	border: 1px solid #aeaeae !important;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
	border-top-left-radius: 0.3rem !important;
	border-top-right-radius: 0.3rem !important;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
	border-bottom-left-radius: 0.3rem !important;
	border-bottom-right-radius: 0.3rem !important;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: #ccc !important;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
	border-bottom-color: theme-color("primary") !important;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
	border-top-color: theme-color("primary") !important;
}

.react-datepicker__close-icon {
	background-color: transparent !important;
}

.react-datepicker__close-icon::after {
	border-radius: 50% !important;
	background-color: theme-color("primary") !important;
	color: #fff !important;
}

.react-datepicker__today-button {
	background: theme-color("light") !important;
	border-top: 1px solid theme-color("light") !important;
	border-radius: 0 0 0.5rem 0.5rem !important;
}

.react-datepicker__portal {
	background-color: rgba(0, 0, 0, 0.8) !important;
}

.react-datepicker__portal .react-datepicker__navigation {
	border: 0.81rem solid transparent !important;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
	border-right-color: theme-color("primary") !important;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
	border-right-color: theme-color("secondary") !important;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
	border-right-color: theme-color("light") !important;
}

.react-datepicker__portal .react-datepicker__navigation--next {
	border-left-color: theme-color("primary") !important;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
	border-left-color: theme-color("secondary") !important;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
	border-left-color: theme-color("light") !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: $primary !important;
}