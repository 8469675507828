/*******************************************************
Variables
*******************************************************/
$white: #FFF;
// Sizes
$scrubber-width-default: 20px;
$scrubber-width-sm: 11px; //10px
$scrubber-width-md: 30px;
$scrubber-width-lg: 40px;

// Scrubber text
// - off
$slider-text-unchecked: "Disabled";
$slider-text-unchecked-color: $black;
// - on
$slider-text-checked: "Enabled";
$slider-text-checked-color: $white;

/*******************************************************
Animation
*******************************************************/

@keyframes popIn {
  0% { transform: scale(1, 1); }
  25% { transform: scale(1.2, 1); }
  50% { transform: scale(1.4, 1); }
  100% { transform: scale(1, 1); }
}

@keyframes popOut {
  0% { transform: scale(1, 1); }
  25% { transform: scale(1.2, 1); }
  50% { transform: scale(1.4, 1); }
  100% { transform: scale(1, 1); }
}

@keyframes splashIn {
  0% { transform: scale(1); opacity: 1; }
  25% { transform: scale(1.1); opacity: .8 }
  50% { transform: scale(1.1); opacity: .9; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes splashOut {
  0% { transform: scale(1); opacity: 1; }
  25% { transform: scale(1); opacity: .8 }
  50% { transform: scale(1); opacity: .9; }
  100% { transform: scale(.5); opacity: 1; }
}

/*******************************************************
Main Slider basics
*******************************************************/

%checkbox-toggle,
.checkbox-toggle {
  position: relative;

  input[type="checkbox"], input[type="radio"] {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0%;
    margin: 0 0;
    cursor: pointer;
    opacity: 0;


    &:focus + *:before {
      box-shadow: 0 0 0 2px $input-focus-border-color;
      //outline: 2px solid $input-focus-border-color;
    }
  }

  input + span {
    cursor: pointer;
    user-select: none;
    display: inline-block;
    margin-top: .25rem; //Added property
    margin-bottom: .25rem;

    &:before {
      position: absolute;
      top: 4px;
      left: 0px;
      display: inline-block;
    }

    > h4 {
      display: inline;
    }
  }
}

.form-horizontal [class^='checkbox'] {
  input + span:after {
    top: 7px;
  }
}

/*******************************************************
Main Slider
*******************************************************/

%checkbox-slider {
  @extend %checkbox-toggle;

  input {

    + span {
      padding-left: $scrubber-width-default * 2;

      &:before {
        content: "";
        height: $scrubber-width-default;
        width: $scrubber-width-default * 2;
        background: rgba(100, 100, 100, .2);
        box-shadow: inset 0 0 5px rgba(0, 0, 0, .8);
        transition: background .2s ease-out;
      }
      // Off State
      &:after {
        width: $scrubber-width-default;
        height: $scrubber-width-default;
        position: absolute;
        left: 0px;
        top: 4px; //0
        display: block;
        background: $white;
        transition: margin-left 0.1s ease-in-out;
        text-align: center;
        font-weight: bold;
        content: "";
      }
    }
    // On state
    &:checked + span:after {
      margin-left: $scrubber-width-default;
      content: "";
    }
    &:checked + span:before {
      transition: background .2s ease-in;
    }

  }

}


.checkbox-slider {
  @extend %checkbox-toggle;
  @extend %checkbox-slider;
}

/*******************************************************
Slider default
*******************************************************/

%checkbox-slider--default {
  @extend %checkbox-slider;

  input + span:after {
    background: $white;
    border: solid transparent 1px;
    background-clip: content-box;
  }

  input:checked + span:after {
    background: $success;
    border: solid transparent 1px;
    background-clip: content-box;
  }

}

.checkbox-slider--default {
  @extend %checkbox-slider--default;
}

/*******************************************************
Slider default rounded
*******************************************************/

.checkbox-slider--a-rounded {
  @extend %checkbox-slider--default;

  input + span:after,
  input + span:before {
    border-radius: $border-radius;
  }

  input + span:after,
  input:checked + span:after {
    border: solid transparent 2px;
    background-clip: content-box;
  }
}

/*******************************************************
Slider default rounded Sizes
*******************************************************/

.checkbox-slider--a-rounded {

  &.checkbox-slider-sm {

    input + span:before,
    input + span:after {
      border-radius: $border-radius-sm;
    }
  }

  &.checkbox-slider-md {

    input + span:before,
    input + span:after {
      border-radius: $border-radius;
    }
  }

  &.checkbox-slider-lg {

    input + span:before,
    input + span:after {
      border-radius: $border-radius-lg;
    }
  }

}

/*******************************************************
Slider B
*******************************************************/

%checkbox-slider--b {
  @extend %checkbox-slider;

  input {

    + span {
      padding-left: $scrubber-width-default * 2;
    }

    + span:before {
      border-radius: $scrubber-width-default;
      width: $scrubber-width-default * 2;
    }

    + span:after {
      background: $white;
      content: "";
      width: $scrubber-width-default;
      border: solid transparent 2px;
      background-clip: padding-box;
      border-radius: $scrubber-width-default;
    }
    &:not(:checked) + span:after {
      animation: popOut ease-in .3s normal;
    }
    &:checked + span:after {
      content: "";
      margin-left: $scrubber-width-default;
      border: solid transparent 2px;
      background-clip: padding-box;
      animation: popIn ease-in .3s normal;
    }

    &:checked + span:before {
      background: $primary;
    }
  }

}

.checkbox-slider--b {
  @extend %checkbox-slider--b;
}

/*******************************************************
Slider B Sizes
*******************************************************/

.checkbox-slider--b {

  &.checkbox-slider-md {

    input + span:before {
      border-radius: $scrubber-width-md;
    }

    input + span:after {
      border-radius: $scrubber-width-md;
    }

  }

  &.checkbox-slider-lg {

    input + span:before {
      border-radius: $scrubber-width-lg;
    }

    input + span:after {
      border-radius: $scrubber-width-lg;
    }

  }
}

/*******************************************************
Slider B-flat
*******************************************************/

.checkbox-slider--b-flat {
  @extend %checkbox-slider--b;

  input {

    + span:before {
      box-shadow: none;
    }

  }

}

.checkbox-slider--b-flat.checkbox-slider-left{
    label{
        position:relative;
    }
    span{
        margin-left:10px;
        padding-left: 0;
        padding-right: 60px
   }

    input + span:before{
         right: -20px;
         left: auto;
    }
    input + span:after{
        right: 10px;
        left: auto;
    }

    input:checked + span:after{
        right:-20px;
        left:auto;
        margin-right: 0;
   }
}

/******************************************************
State Disabled
*******************************************************/

.checkbox-slider--default input:disabled {
  + span:after { background: $gray-500; }
  + span:before { box-shadow: 0 0 0 black; }
  + span {
    color: $gray-500;
  }
}

.checkbox-slider--a-rounded input:disabled,
.checkbox-slider--a input:disabled {
  + span:after { background: $gray-500; color: $white; }
  + span:before { box-shadow: 0 0 0 black; }
  + span {
    color: $gray-500;
  }
}

.checkbox-slider--b-flat input:disabled,
.checkbox-slider--b input:disabled {

  + span:after {

    border: solid transparent 2px;
    border-radius: $scrubber-width-lg;
  }
  + span:before { box-shadow: 0 0 0 black; }
  + span {
    color: $gray-500;
  }

   &:checked + span:before {
    background: $gray-500;
  }
}

.checkbox-slider--c-weight input:disabled,
.checkbox-slider--c input:disabled {
  &:checked + span:after {
    background: $gray-500;
  }

  + span:after {
    border-color: $gray-500;
  }
  + span:before { background: $gray-500; }
  + span {
    color: $gray-500;
  }

}

/*******************************************************
Indicators
*******************************************************/
input:checked + .indicator-primary {
  color: $primary;
}

input:checked + .indicator-success {
  color: $success;
}

input:checked + .indicator-info {
  color: $info;
}

input:checked + .indicator-warning {
  color: $warning;
}

input:checked + .indicator-danger {
  color: $danger;
}

/*******************************************************
Sizes
*******************************************************/

// Small
.checkbox-slider-sm {
  line-height: $scrubber-width-sm;

  input + span {
    padding-left: $scrubber-width-sm;
  }

  input + span:before {
    width: $scrubber-width-sm * 2;
  }

  input + span:after,
  input + span:before {
    height: $scrubber-width-sm;
    line-height: $scrubber-width-sm;
  }

  input + span:after {
    width: $scrubber-width-sm;
    vertical-align: middle;
    top: 4px;
  }

  input:checked + span:after {
    margin-left: $scrubber-width-sm;
  }
}

// Medium
.checkbox-slider-md {
  line-height: $scrubber-width-md;

  input + span {
    padding-left: $scrubber-width-md * 2;
  }

  input + span:before {
    width: $scrubber-width-md * 2;
  }

  input + span:after,
  input + span:before {
    height: $scrubber-width-md;
    line-height: $scrubber-width-md;
  }

  input + span:after {
    width: $scrubber-width-md;
    vertical-align: middle;
  }

  input:checked + span:after {
    margin-left: $scrubber-width-md;
  }
}

// Large
.checkbox-slider-lg {
  line-height: $scrubber-width-lg;

  input + span {
    padding-left: $scrubber-width-lg * 2;
  }

  input + span:before {
    width: $scrubber-width-lg * 2;
  }

  input + span:after,
  input + span:before {
    height: $scrubber-width-lg;
    line-height: $scrubber-width-lg;
  }
  input + span:after {
    width: $scrubber-width-lg;
    vertical-align: middle;
  }

  input:checked + span:after {
    margin-left: $scrubber-width-lg;
  }
}

/*******************************************************
Variations
*******************************************************/
// primary
.checkbox-slider-primary {
  &.checkbox-slider--default,
  &.checkbox-slider--a,
  &.checkbox-slider--a-rounded,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      background: $primary;
      background-clip: content-box;
    }
  }
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      border-color: $primary;
    }
  }

  &.checkbox-slider--b,
  &.checkbox-slider--b-flat,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:before {
      background: $primary;
    }
  }
}

// info
.checkbox-slider-info {
  &.checkbox-slider--default,
  &.checkbox-slider--a,
  &.checkbox-slider--a-rounded,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      background: $info;
      background-clip: content-box;
    }
  }
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      border-color: $info;
    }
  }

  &.checkbox-slider--b,
  &.checkbox-slider--b-flat,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:before {
      background: $info;
    }
  }
}

// warning
.checkbox-slider-warning {
  &.checkbox-slider--default,
  &.checkbox-slider--a,
  &.checkbox-slider--a-rounded,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      background: $warning;
      background-clip: content-box;
    }
  }
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      border-color: $warning;
    }
  }

  &.checkbox-slider--b,
  &.checkbox-slider--b-flat,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:before {
      background: $warning;
    }
  }
}

// danger
.checkbox-slider-danger {
  &.checkbox-slider--default,
  &.checkbox-slider--a,
  &.checkbox-slider--a-rounded,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      background: $danger;
      background-clip: content-box;
    }
  }
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:after {
      border-color: $danger;
    }
  }

  &.checkbox-slider--b,
  &.checkbox-slider--b-flat,
  &.checkbox-slider--c,
  &.checkbox-slider--c-weight {
    input:checked + span:before {
      background: $danger;
    }
  }
}

