// @import url('https://fonts.googleapis.com/css?family=Krub:300,400,500,700|Oswald:300,400,500,600,700');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,500,600,700|Fira+Sans:300,400,500,600,700');

* {
    font-family: $main-font;
    &::-moz-selection {
    	color: $white;
    	background: $primary;
    }
    &::selection {
    	color: $white;
    	background: $primary;
    }
}
