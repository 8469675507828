.btn.btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

.btn {
    font-size: .875rem;
    font-weight: 500;
    text-transform: uppercase;

    &-circle {
        background-color: $gray-300;
        border: 2px solid;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:hover {
            background-color: $link-color;
            color: $gray-100;
            text-decoration: none;
        }

        &:disabled {
            opacity: .8;
            cursor: not-allowed;

            &:hover {
                background-color: $gray-200;
            }
        }

        i {
            font-size: 1.2rem;
        }
    }

    &-rounded {
        border-radius: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &-primary {
        color: $white;
    }

    &-xl {
        border-width: 3px;
        border-radius: .75rem;
        font-size: 1.15rem;
        line-height: 1.25;
    }

    &-white {
        background-color: rgba($white, .8);
        color: $gray-800;
        &:hover {
            background-color: rgba($white, 1);
        }
    }

    &-outline-white {
        border: 1px solid $white;
        color: $white;
        background-color: transparent;
        &:hover {
            background-color: rgba($white, .4);
        }
    }

    &-dashboard {
        font-size: 1rem;
        color: $white;
        background-color: transparent;
        border: 2px solid $white;
        border-radius: 20px;
        display: block;
        width: 100%;

        &:hover {
            color: $white;
            background-color: rgba($black, .2);
        }
    }

    &-upsale {
        color: $cmv-upsale-accent;
        // color: $white;
        border: 1px solid $cmv-upsale-accent;
        // border: 1px solid $white;
        border-radius: 20px;

        &:hover {
            color: $white;
            background-color: rgba($cmv-upsale-accent, .8);
        }
    }

    &-summary {
        font-size: .85rem;
        border-radius: 5px;
        padding: .25rem .5rem;
        color: $gray-100;
        border: 1px solid $gray-100;

        &:hover {
            color: $white;
            background-color: rgba($white, .3);
            text-decoration: none;
        }

        &-toggle {}

        &-collapse {}
    }

    &-toggle-package {
        color: $gray-700;
        font-size: 1.2rem;

        &:hover {
            color: $primary;
            text-decoration: none;
        }

        i {
            font-size: 1.4rem;
            margin-right: .25rem;
        }

        &.package-enabled-locked {
            color: $primary;
            cursor: not-allowed;
            pointer-events: none;
            opacity: .8;
        }

        &.package-disabled {
            color: $gray-700;
        }

        &.package-enabled {
            color: $primary;
        }
    }

    &-desc-toggler {
        color: $primary;
    }

    &-floater {
        @media (min-width: map-get($grid-breakpoints, xl)) {
            display: none;
        }

        cursor: pointer;
        opacity: .8;
        display: flex;
        align-items: center;
        position: sticky;
        z-index: 1000;
        margin-left: auto;
        margin-right: auto;
        width:40px;
        height:40px;
        bottom:10px;
        left:calc(50% - 20px);
        background-color:$indigo;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        box-shadow: 0px 2px 3px #999;
        transition: all,
        .5s;

        i {
            margin-left: auto;
            margin-right: auto;
            font-size: 1.2rem;
        }

        p {
            font-size: .75rem;
        }

        &:hover {
            color: $white;
            text-decoration: none;
            opacity: 1;
        }

        &-to-available {
            background-color: $primary;
        }

        &-to-services {
            background-color: $primary;
        }
    }

    &-icon {
        outline: none;
        padding-left: .5rem;
        padding-right: .5rem;
        border: 1px solid transparent;
        color: $gray-500;

        &:disabled {
            color: $gray-500 !important;
        }

        &:focus {
            box-shadow: none;
        }

        &:hover:not(:disabled) {
            color: $gray-700;
            border-color: $gray-200;
        }

        > i {
            font-size: 1.6rem;
        }

        &-inactive {
            pointer-events: none;
        }

        &-primary {
            color: $primary;
        }

        &-info {
            color: $info;
        }

        &-success {
            color: $success;
        }

        &-warning {
            color: $warning;
        }

        &-danger {
            color: $danger;
        }

        &-white {
            color: $white;
        }

        // Sizes
        &-lg {
            >i {
                font-size: 2.25rem;
            }
        }
    }

    &-badge {
    	cursor: pointer;
    }
}