.dot {
    display: inline-block;
    border-radius: 50%;
    width: 50%;
    background-color: $gray-100;
    border: 1px solid $gray-300;
    height: 12px;
    width: 12px;
    margin: 0;

    // Dots colors
    &.dot-success {
        background-color: $success;
        border-color: $success;
    }

    &.dot-outline-success {
        border-color: $success;
    }

    &.dot-warning {
        background-color: $warning;
        border-color: $warning;
    }

    &.dot-outline-warning {
        border-color: $warning;
    }

    &.dot-danger {
        background-color: $danger;
        border-color: $danger;
    }

    &.dot-outline-danger {
        border-color: $danger;
    }
    // Dots colors Ends

    // Dots Sizes
    &.dot-sm {
        height: 10px;
        width: 10px;
    }

    &.dot-lg {
        margin: 0 2px;
        height: 20px;
        width: 20px;
    }
    // Dots Sizes Ends
}