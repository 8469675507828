.card {

    &-body {
        .card-title {
            margin-bottom: 0.25rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                margin-bottom: .75rem;

            }
        }
    }

    // Icons in Card Headers
    &-header {
        .fa {
            transition: .3s transform ease-in-out;
        }

        .collapsed {
            .fa {
                transform: rotate(180deg);
            }
        }
    }
    // Icons in Card Headers Ends

    // Card Login
    &-login {
        flex: 1;
        background-color: $white;
        border-radius: $border-radius-lg;
        @include cmv-box-shadow();
        padding: 1rem;
        border: 1px solid $gray-300;
        margin: auto;

        &-header {

            .brand-container {
                height: 70px;
                background-image: url("../../images/camvio-banner.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                justify-content: center;
            }

            h3 {
                font-weight: 300;
                text-align: center;
                padding: .5rem 0;
                margin-bottom: .5rem;
            }
        }
    }

    // Card Login Ends

    // Card Accordion
    &-accordion {
        border-width: 0;
        margin-bottom: 0.5rem;

        .card-header {
            border-bottom-width: 0;
            border: 1px solid $main;
            background-color: $white;
            padding: 1rem 1.5rem;

            &:hover {
                background-color: $main;
            }

            h5 {
                font-weight: 300;
                font-size: 1.5rem;
                text-transform: uppercase;
                letter-spacing: .5px;
                margin-bottom: 0;

                a {
                    display: block;
                    color: $black;
                    text-decoration: none;
                }
            }
        }

        .card-body {
            h6 {
                font-size: 1.1rem;
                margin-bottom: 0.75rem;
                color: $gray-700;
            }

            .tab-pane {
                border-width: 0 2px 2px 2px;
                border-style: solid;
                border-color: $gray-300;
            }
        }
    }

    // Card Accordion Ends

    // Card Toggable
    &-toggable {
        border-bottom: 1px solid $gray-200;

        // margin-bottom: .25rem;
        &-header {
            padding: .25rem 0 .25rem 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .h-with-check-title, .h-title {
                color: $gray-700;
                font-size: 1rem;
                cursor: pointer;

                &:before {
                    display: inline-block;
                    width: 1rem;
                    margin-right: .5rem;
                    font-family: $icon-font;
                    font-weight: 900;
                    content: "\f105";
                }

                &.toggled {
                    &:before {
                        content: "\f107";
                    }
                }
            }

            .h-check {
                flex-direction: row;
                margin-top: 0;
                margin-bottom: 0;
                margin-left: auto;

                .form-check {

                    label {
                        margin-bottom: 0;

                        span {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    // Card Toggable Ends

    // Card Horizontal
    &-horizontal {
        display: flex;
        flex-direction: column;

        @media (min-width: map-get($grid-breakpoints, md)) {
            flex-direction: row;
        }

        border: 1px solid $gray-300;
        border-radius: 5px;
        margin-top: .75rem;
        margin-bottom: .75rem;

        .card-header {
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            align-content: center;
            justify-content: flex-start;
            padding: .5rem 1rem;
            border-radius: 4px;
            border-width: 0;
            border-color: $gray-300;
            border-style: solid;
            border-bottom-width: 1px;
            // background-color: transparent;
            background-color: $gray-100;
            color: $primary;

            @media (min-width: map-get($grid-breakpoints, md)) {
                border-width: 0px;
                border-right-width: 1px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            i {
                font-size: 1.375rem;
                margin: 0 auto;
                color: $gray-600;

                @media (min-width: map-get($grid-breakpoints, md)) {
                    font-size: 2.5rem;
                }
            }
        }

        .card-body {
            padding: .5rem;

            h5 {
                color: $gray-700;
            }
        }

        .card-footer {
            padding: .5rem 1rem;
            min-width: 120px;
            border-width: 0;
            background-color: $gray-100;

            .btn {
                align-self: flex-start;
                margin-top: auto;
                margin-bottom: .25rem;
                display: block;
                width: 100%;
            }
        }
    }

    // Card Horizontal Ends

    // Card Upsale
    &-upsale {
        border: 2px solid rgba($white, .4);
        color: $cmv-upsale-accent;
        // color: $white;
        background-color: lighten($cmv-upsale-accent, 55%);
        // background-image: linear-gradient(250deg, lighten($cmv-upsale-accent, 10%) 0%, $cmv-upsale-accent 50%, darken($cmv-upsale-accent, 10%) 100%);

        .card-header {
            border-color: lighten($cmv-upsale-accent, 45%);

            i {
                color: lighten($cmv-upsale-accent, 25%);
                // color: $white;
            }
        }

        .card-body {

            p:last-of-type {
                margin-bottom: 0;
            }

            .btn:disabled {
                color: rgba($white, .5);
            }

            .upsale-item {
                padding-top: .5rem;
                padding-bottom: .5rem;
                border-bottom: 1px solid rgba($white, .2);

                .header {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .header-title {
                        font-size: 1.25rem;
                        font-weight: 300;
                    }

                    .header-toolbar {
                        margin-left: auto;
                        .services {
                            font-size: 1.25rem;
                            margin-right: 1rem;
                            i {
                                opacity: .85;
                                margin-right: .25rem;
                            }
                        }

                        .btn {
                            margin-right: .25rem;
                        }

                        .btn-white {
                            color: $cmv-upsale-accent;
                        }
                    }
                }

                .details {
                    width: 100%;
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                    border-radius: .75rem;
                    background-color: rgba($white, .1);

                    .img-upsale {
                        width: 100%;
                        margin-bottom: 1rem;
                        border-top-left-radius: .75rem;
                        border-top-right-radius: .75rem;
                        border-bottom: 1px solid rgba($black, .2);
                    }

                    .description {
                        padding: 1rem;
                    }
                }
            }
        }

        .card-footer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-color: transparent;
            flex-wrap: nowrap;
            position: relative;
            min-width: 0;

            .btn-circle {
                background-color: $cmv-upsale-accent;
                border: 2px solid $white;
                display: inherit;
                position: absolute;
                top: -16px;
                right: -16px;
                width: 38px;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    // Card Upsale Ends

    // Card Bundle
    &-bundle {
        &-selected {
            border-color: $primary;
        }
    }

    // Card Bundle Ends

    // Extra Card Sizes
    &-xs {
        border: 1px solid $gray-300;
        margin-bottom: .25rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-bottom: 1rem 0;
        }

        .card-header {
            color: $gray-500;
            background-color: transparent;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            padding: 0.25rem;
            border-bottom-color: transparent;
        }

        .card-body {
            color: $gray-800;
            font-size: 1.2rem;
            padding: 0.1rem 0.25rem;
        }
    }

    &-sm {
        border-color: $gray-300;

        .card-header {
            background-color: $gray-100;
            color: $gray-500;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0.5rem 1rem;
            border-bottom-color: $gray-300;
            display: flex;
            justify-content: space-between;

            span:last-child {
                text-transform: none;
                font-weight: 500;
                color: $gray-600;
                text-align: right;
            }
        }

        .card-body {
            padding: 0.5rem 1rem;

            p {
                margin-bottom: 0;
            }

            .lead {
                font-size: 1.75rem;
                font-weight: 500;
            }
        }
    }

    // Extra Card Sizes Ends
}

.tab-pane {

    &.card-body {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    &-full-size {
        margin: 0 (-0.5rem);
        padding: 0;

        @media (min-width: map-get($grid-breakpoints, md)) {
            margin: 0 (-1rem);
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            height: 100%;
        }
    }

    &-message-empty {
        margin-top: 1rem;
    }

    .card {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
}

// Cards in Contexts
#container-client-services-overview-content {
    .card-upsale {
        color: $white;
        background-color: lighten($cmv-upsale-accent, 5%);

        .card-header {
            background-color: lighten($cmv-upsale-accent, 5%);
            border-color: lighten($cmv-upsale-accent, 15%);

            i {
                color: $white;
            }
        }

        .card-footer {
            .btn-upsale {
                color: $white;
                border-color: $white;
            }
        }

    }
}