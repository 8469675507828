// Make sure placeholder texts are transparent
.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}
.form-label-group textarea::-webkit-input-placeholder {
  color: transparent;
}
.form-label-group select::-webkit-input-placeholder {
  color: transparent;
}
.form-label-group input:-ms-input-placeholder {
  color: transparent;
}
.form-label-group textarea:-ms-input-placeholder {
  color: transparent;
}
.form-label-group select:-ms-input-placeholder {
  color: transparent;
}
.form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.form-label-group textarea::-ms-input-placeholder {
  color: transparent;
}
.form-label-group select::-ms-input-placeholder {
  color: transparent;
}
.form-label-group input::-moz-input-placeholder {
  color: transparent;
}
.form-label-group textarea::-moz-input-placeholder {
  color: transparent;
}
.form-label-group select::-moz-input-placeholder {
  color: transparent;
}
.form-label-group input::placeholder {
  color: transparent;
}
.form-label-group textarea::placeholder {
  color: transparent;
}
.form-label-group select::placeholder {
  color: transparent;
}


.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label,
.form-label-group .input-group input,
.form-label-group .input-group label {
  padding: .375rem .75rem;
}

// Make the label act as Placeholder
.form-label-group {
  label,
  .input-group input ~ label {
    position: absolute;
    top: 1px;
    left: 0px;
    display: block;
    // width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: $gray-700;
    transition: all .1s ease-in-out;
  }
  > .form-control-sm ~ label {
    font-size: 0.875rem;
    top: -7px;
  }
}


// Floating Labels
.form-label-group input:focus ~ label,
.form-label-group .input-group input:focus ~ label,
.form-label-group textarea:focus ~ label,
.form-label-group select:focus ~ label,
.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group .input-group input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label,
.form-label-group select:not(:placeholder-shown) ~ label {
  padding: 0 0.2rem;
  font-size: .75rem;
  font-weight: 700;
  background-color: $white;
  color: $link-color;
  text-transform: uppercase;
  top: -10px;
  left: .5rem;
}

// Error State
.form-label-group input.is-invalid:focus ~ label,
.form-label-group .input-group input.is-invalid:focus ~ label,
.form-label-group textarea.is-invalid:focus ~ label,
.form-label-group select.is-invalid:focus ~ label,
.form-label-group input.is-invalid:not(:placeholder-shown) ~ label,
.form-label-group .input-group input.is-invalid:not(:placeholder-shown) ~ label,
.form-label-group textarea.is-invalid:not(:placeholder-shown) ~ label,
.form-label-group select.is-invalid:not(:placeholder-shown) ~ label {

}

// Label for compound control
.form-label-group .input-group {
  .input-group-prepend,
  .input-group-append {
    //height: 45px;
  }
  .input-group-middle {
    margin-left: -2px;
  }
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    left: .5rem;
    z-index: 20;
  }
}