.accordion {
    // transition: all .5s;

    &-section-header {
        display: block;
        width: 100%;
        color: $gray-700;
        font-weight: 500;
        font-size: 1.5rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        margin-bottom: 1rem;

        i {
            width: 1.75rem;
            text-align: center;
        }
    }

    h3,
    h4 {
        background-color: $white;
        color: $gray-600;
        font-size: 1.5rem;
        text-transform: uppercase;
        padding-top: .75rem;
        padding-bottom: .75rem;
        margin-top: .25rem;
        margin-bottom: .25rem;
        border-bottom: 1px solid $gray-300;
        cursor: pointer;
        width: 100%;

        i {
            width: 1.75rem;
            text-align: center;
        }

        // Make it sticky
        position: sticky;
        top: 0;
        z-index: 900;

        // Open Icon
        &:before {
            display: inline-block;
            width: 1.2rem;
            margin-right: .5rem;
            font-family: $icon-font;
            font-weight: 900;
            content: "\f078";
        }

        // Closed Icon
        &.collapsed {
            &:before {
                font-family: $icon-font;
                font-weight: 900;
                content: "\f054";
            }
        }

        // Clearfix when we have badges
        &:after {
            display: block;
            clear: both;
            content: "";
        }
    }

    h4 {
        text-transform: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .plan-header {
        h4 {
            font-size: 1.25rem;
            padding-top: .25rem;
            padding-bottom: .25rem;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            border-bottom-width: 0;
        }
    }

    // Fix margin collapsing when animating
    .container-accordion-content {
        >div {
            border-top: 1px solid transparent;
        }
    }
}