// Camvio Cloud Panels
.cmv-container-panels {
    padding: 0 0.5rem 0.5rem;

    @media (min-width: map-get($grid-breakpoints, md)) {
        padding: 0 1rem 0.5rem;
    }

    @media (min-width: map-get($grid-breakpoints, xl)) {
        padding: 0 2rem 0.5rem;

        > .row {
            margin: 0 -1rem;

            > [class*="col"] {
                padding: 0 1rem;
                transition-property: flex, flex-basis, flex-grow, flex-shrink;
                transition-duration: .4s;
                transition-timing-function: ease;

                &:first-child {padding-right: 0.5rem;}
                &:last-child {padding-left: 0.5rem;}
            }
        }

        @media (min-height: map-get($grid-breakpoints, md)) {
            flex-grow: 1;
            height: 100%;
            overflow: hidden;

            > .row,
            > .row > [class*="col"],
            .panel-left,
            .panel-right {
                height: 100%;
            }

            .panel-left,
            .panel-right {
                display: flex;
                flex-direction: column;

                .nav {
                    flex-shrink: 0;
                }

                .tab-content {
                    flex-grow: 1;
                    overflow-y: scroll;
                    
                    &-full-size {
                        overflow-y: auto;
                    }

                    .accordion h3,
                    .accordion h4 {
                        top: -1px;
                    }
                }
            }
        }
    }

    .panel-left,
    .panel-right {
        margin-bottom: 0.5rem;
        background: white;
        border: 1px solid $gray-300;
        border-radius: 0.5rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-bottom: 1rem;
        }

        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin-bottom: 0;
        }

        .nav.nav-scrollable {
            height: auto;
            padding-bottom: 0.25rem;
            border-bottom: 1px solid $gray-200;
            overflow-x: auto;

            @media (min-width: map-get($grid-breakpoints, xl)) {
                //padding-left: 1rem;
                //padding-right: 1rem;
            }

            .nav-link {
                padding: 0.5rem 1rem 0.25rem;
                font-weight: 500;
                font-size: 1rem;

                @media (min-width: map-get($grid-breakpoints, sm)) {
                    padding: 0.5rem 0.75rem 0.25rem;
                }

                &:not(.active) {
                    color: inherit;
                }

                &.active {
                    background: transparent;
                    box-shadow: 0 20px 0 -16px $link-color;
                }
            }

            .btn {
                border: none;
            }
        }

        .tab-pane-toolbar {

            .btn-success {
                color: $white;

                &:not(:disabled):hover {
                    color: $black;
                }
            }
        }

        .tab-content {
            padding: 0 0.5rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: 0 1rem;
            }

            .order-wrapper {

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .panel-left {

        .btn-customer-portal {

            i {

                &:first-child:nth-last-child(2) {
                    transform: translateX(-4px);
                }

                &:last-child:nth-child(2) {
                    transform: translate(16px, 13px);
                    background: white;
                    width: 1.5em;
                    line-height: 1.3;
                    border-top-left-radius: 3px;
                    font-size: 1.1em;
                }
            }
        }
    }

    .panel-right {

        .tab-content {

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: 1rem;
            }
        }

        .tab-pane-toolbar {
            margin-top: 0;
        }

        .form-section:last-child .form-subsection:last-child {
            margin-bottom: 0;
        }

        .card-toggable {

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .pagination-custom {

        .pagination-header,
        .pagination-results {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .pagination-header,
        .pagination-footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (min-width: map-get($grid-breakpoints, sm)) {
                flex-direction: row;
                justify-content: space-between;
            }

            > label,
            > span {
                margin: 0;

                @media (max-width: map-get($grid-breakpoints, sm) - 1) {
                    margin-bottom: 1rem;
                    line-height: 1;
                }
            }

            input {
                width: auto;

                @media (min-width: map-get($grid-breakpoints, sm)) {
                    width: 16rem;
                }
            }
        }

        .pagination-footer {
            margin-bottom: 0.5rem;

            .pagination {
                margin-bottom: 0;
            }
        }

        .page-item {

            .page-link {

                @media (max-width: map-get($grid-breakpoints, sm) - 1) {
                    padding: 0.5rem;
                    font-size: 0.85rem;
                }
            }

            &.active .page-link {
                cursor: default;
            }
        }
    }
}

.panel-summary {
    display: flex;
    margin-left: 0;
    margin-right: 0;
}