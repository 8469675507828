// Camvio Cloud Loading Spinner

.with-loader {
    position: relative;
}

.cmv-loader {
    background-color: rgba(255, 255, 255, .85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .loader {
        background-image: url(/img/loader.svg);
        background-repeat: no-repeat;
        background-position: center;
        animation-name: loader-spin;
        animation-duration: .5s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        display: inline-block;
        width: 42px;
        height: 42px;
        margin: auto;
    }

    p {
    	margin: 1rem auto;
    	color: $primary;
    	font-size: 1.25rem;
    	text-transform: uppercase;
    }

}