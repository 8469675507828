.cmv-header,
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    transition: all 0.2s ease-in-out;
}

/*----------------toggled sidebar----------------*/

.page-wrapper {
    transition: all 0.2s ease-in-out;

    @media (max-width: map-get($grid-breakpoints, lg) - 1) {

        &-sidebar {

            .page-content,
            .cmv-header,
            .sidebar-wrapper {
                //transform: translateX(0px);
            }

            &.toggled {

                .page-content,
                .cmv-header,
                .sidebar-wrapper {
                    //transform: translateX(260px);
                    margin-left: 260px;
                }
            }
        }
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {

        &-sidebar {
            padding-left: 260px;

            .page-content {
                min-width: calc(100vw - 260px);
            }
        }
    }
    @media (min-width: 1920px) {

        &-sidebar {
            padding-left: 320px;

            .page-content {
                min-width: calc(100vw - 320px);
            }
        }
    }
}

.sidebar-wrapper {
    position: absolute;
    top: 0;
    left: -260px;
    bottom: 0;
    width: 260px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
        top: 3.75rem;
        left: 0;
    }
    @media (min-width: 1920px) {
        width: 320px;
    }
    @media (min-width: 1920px) {
        top: 5rem;
    }
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-wrapper a {
    text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    padding: 0.75rem 0 0;
    color: white;
    overflow-y: auto;

    @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-top: 1rem;
    }
    @media (min-width: map-get($grid-breakpoints, md) * 2) {
        padding-top: 2rem;
    }
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
    display: flex;
    align-items: center;
    padding: $navbar-brand-padding-y + $navbar-padding-y;
    background-color: $gray-600;
}

.sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: 700;
    flex-grow: 1;
}

/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 60px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
    float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
    display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
    color: $success;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper {
    .sidebar-search {
        > div {
            padding: 10px 20px;
        }

        // Remove the arrow marker from the search field
        .selectize-control {
            &:after {
                display: none;
            }
        }
    }
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
    font-size: 0.875rem;

    @media (max-width: map-get($grid-breakpoints, md) - 1) {
        padding-bottom: 0.75rem;
    }

    @media (min-width: 1920px) {
        font-size: 1rem;
    }

    .header-menu span {
        display: block;
        padding: 1.5rem 1rem 0.5rem;
        font-weight: 700;
        font-size: 0.875rem;
        text-transform: uppercase;


        @media (min-width: map-get($grid-breakpoints, xl)) {

            @media (max-height: map-get($grid-breakpoints, md) - 1) {
                padding-top: 1rem;
                font-size: 0.75rem !important;
            }
        }
        @media (min-width: 1920px) and (min-height: map-get($grid-breakpoints, xl)) {
            padding-left: 2rem;
        }
    }

    ul:first-child .header-menu span {
        padding-top: 0;
    }

    ul li a {
        display: inline-block;
        width: 100%;
        text-decoration: none;
        position: relative;
        padding: 0.5rem 1rem;

        @media (min-width: map-get($grid-breakpoints, xl)) {

            @media (min-height: map-get($grid-breakpoints, lg)) {
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
            }
            @media (min-height: map-get($grid-breakpoints, xl)) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
        @media (min-width: 1920px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        i {
            width: 1.25rem;
            margin-right: 0.5rem;
            text-align: center;

            @media (min-width: 1920px) {
                margin-right: 1rem;
            }
        }
    }

    .sidebar-dropdown {

        > a::after {
            content: "\f107";
            position: absolute;
            top: 50%;
            right: 1rem;
            font-weight: 900;
            font-family: $icon-font;
            font-style: normal;
            transform: translateY(-50%);

            @media (min-width: 1920px) {
                right: 2rem;
            }
        }

        &.active > a::after {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .sidebar-submenu {
        display: none;

        &-info {

            @media (min-width: map-get($grid-breakpoints, xl)) {
                padding: 0.5rem 0;
            }
        }

        .sidebar-nav-link {

            &-info {

                a {
                    padding: 0.5rem 1rem;
                    background: transparent !important;
                    cursor: default;
                }

                &-text {
                    opacity: .75;
                }
            }

            a {
                padding-left: 2.75rem;
                font-size: 0.75rem;

                @media (min-width: 1920px) {
                    padding-left: 4.25rem;
                    font-size: 0.875rem;
                }
            }

            .badge {
                float: right;
                vertical-align: middle;
                padding: .25rem .5rem;
                margin-top: 0.125rem;
                font-size: 0.625rem;
            }
        }
    }
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
}

.sidebar-version {
    padding: 0.25rem;
    margin-bottom: 0;
    font-size: 0.75rem;
    text-align: center;
    color: inherit;
    opacity: .5;
}

.sidebar-footer > a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
}

.sidebar-footer > a .notification {
    position: absolute;
    top: 0;
}

.badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
}

.badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: sonar 1.5s infinite;
}

/*-----------------------------cmv-theme-------------------------------------------------*/

.cmv-theme .sidebar-wrapper {
    background: $gray-800;

    background: $sidebar-bg;
}

.cmv-theme .sidebar-wrapper .sidebar-header,
.cmv-theme .sidebar-wrapper .sidebar-search,
.cmv-theme .sidebar-wrapper .sidebar-menu {
    //border-top: 1px solid #3a3f48;
}

.cmv-theme .sidebar-wrapper .sidebar-search input.search-menu,
.cmv-theme .sidebar-wrapper .sidebar-search select.search-menu,
.cmv-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.cmv-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.cmv-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.cmv-theme .sidebar-wrapper .sidebar-search input.search-menu,
.cmv-theme .sidebar-wrapper .sidebar-search select.search-menu,
.cmv-theme .sidebar-wrapper .sidebar-search .input-group-text,
.cmv-theme .sidebar-wrapper .sidebar-brand>a,
.cmv-theme .sidebar-wrapper .sidebar-menu ul li a,
.cmv-theme .sidebar-footer>a {
    color: $gray-500;

    color: white;
}

.cmv-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.cmv-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.cmv-theme .sidebar-wrapper .sidebar-header .user-info,
.cmv-theme .sidebar-wrapper .sidebar-brand>a:hover,
.cmv-theme .sidebar-footer>a:hover i {
    color: $link-color;

    color: white;
}

.cmv-theme .sidebar-wrapper .sidebar-menu > ul > li:hover > a,
.cmv-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a,
.cmv-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active li:hover > a {
    background: rgba(0, 0, 0, 0.1);
}

.page-wrapper.cmv-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.cmv-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.cmv-theme .sidebar-wrapper ul li:hover a i,
.cmv-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.cmv-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.cmv-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: $link-color;
    text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);

    color: inherit;
    text-shadow: none;
}

.cmv-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.cmv-theme .sidebar-wrapper .sidebar-search input.search-menu,
.cmv-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #3a3f48;

    background: rgba(0, 0, 0, 0.1);
}

.cmv-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: $gray-600;

    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.1rem;
    color: white;
    opacity: .5;
}

.cmv-theme .sidebar-footer {
    //background: #3a3f48;
    //box-shadow: 0px -1px 5px #282c33;
    //border-top: 1px solid #464a52;
}

.cmv-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.cmv-theme .sidebar-footer>a:last-child {
    border-right: none;
}
