.nav-tabs .nav-link,
.nav-pills .nav-link {
    color: $gray-600;
    // text-transform: uppercase;
    font-family: $main-font-condensed;
    font-weight: 700;
    font-size: 1.25rem;
}

.nav-tabs .nav-link.active,
.nav-pills .nav-link.active {
    color: $link-color;
    font-weight: 500;
    background-color: $white;
    box-shadow: 0px 21px 2px -18px $link-color;
}

.nav-pills-warning .nav-link {
    color: $gray-600;
}

.nav-tabs-warning .nav-link.active,
.nav-pills-warning .nav-link.active {
    color: $warning;
    box-shadow: 0px 21px 2px -18px $warning;
}

.nav-scrollable {
    height: 49px;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    .nav-link {
        white-space: nowrap;
    }
}

.tab-pane-toolbar {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .form-group {
        width: 100%;
        margin-top: 1rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .btn:not(:first-child) {
        margin-left: .5rem;
    }

    .dropdown-menu {
        .form-group {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

// Navs with background and arrows
@each $name,
$color in $theme-colors {
    .nav-pills {
        .nav-item-bg-#{$name}-with-arrow {
            border-radius: 5px;
            background-color: $color;
            position: relative;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 16px 0 16px;
                border-color: $color transparent transparent transparent;
                position: absolute;
                left: calc(50% - 16px);
                bottom: -15px;
            }

            .btn-icon {
                &:hover {
                    border-color: transparent;
                    color: lighten($color, 20%);
                }
            }
        }
    }
}
// Navs with background and arrows Ends