.dropdown {

	&-menu {

		// Dropdown with select
		&-with-select {
			min-width: 225px;
		}
	}

	&-item {

		&.disabled,
		&:disabled {
			pointer-events: auto;
		}
	}
}