.documents-panel {

    &-form {
        margin-bottom: 1rem;

        .btn {
            color: white !important;
        }
    }

    &-droparea {
        padding: 2rem .5rem;
        margin-bottom: 1rem;
        text-align: center;
        border: 2px dashed $primary;
        border-radius: 5px;
        outline: none;
        cursor: pointer;

        i {
            margin-bottom: 1rem;
            font-size: 3rem;
        }

        p {
            margin-bottom: 0;

            &::after {
                content: attr(data-text);
            }
        }

        &-dragging {

            &-valid {
                background: transparentize($success, .9);
                border-color: $success;

                p::after {content: "Drop file!"}
            }

            &-invalid {
                background: transparentize($danger, .9);
                border-color: $danger;

                p::after {content: "Invalid file"}
            }
        }

        &-accepted {
            background: transparentize($success, .9);
            border-color: $success;

            p::after {content: attr(data-text)}
        }

        &-required {
            background: transparentize($danger, .9);
            border-color: $danger;

            p::after {content: "Please select file"}
        }

        &-disabled {
            opacity: .5;
            cursor: default;
        }
    }

    &-name {
        margin-bottom: 0.25rem;
        font-weight: 500;

        span {
            display: block;
            font-weight: 400;
            font-size: .75rem;
            color: $gray-600;
        }
    }

    &-details {
        margin-bottom: 0;
        font-size: .75rem;
        line-height: 1.3;
        color: $gray-600;

        span {
            margin: 0 0.5rem 0 0;
        }

        i {
            margin-right: 0.25rem;
        }
    }

    .form-subsection {
        padding-bottom: 0;
        margin-bottom: 0;

        .form-group {
            padding: 0.5rem 0.5rem 0.75rem;
            margin: 0 -0.5rem;

            &:nth-child(2) {
                padding-top: 0;
            }

            &:nth-child(odd) {
                background: white;
                border-top: 1px solid $gray-200;
                border-bottom: 1px solid $gray-200;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .btn {
            padding: 0.1rem 0.5rem;
            border: none;

            &:hover:not([disabled]) {
                color: white;
            }
        }
    }
}