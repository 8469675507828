// Camvio Cloud DataRow Component
.cmv-datarow {
    padding: .375rem 0;
    border-top: 1px solid $gray-200;

    &:hover {
        background-color: $gray-100;
    }

    &-container {
        padding: 1rem .375rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-left: 2rem;
            margin-right: 2rem;
        }

        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin-left: 3rem;
            margin-right: 3rem;
        }

        &-header, &-footer {

            .key {
                color: $gray-700;
                font-size: 0.875rem;
            }

            .value {
                font-size: 1.375rem;
                font-weight: 700;
            }
        }

        &-header {
            border-bottom: 2px solid $gray-200;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }

        &-footer {
            border-top: 2px solid $gray-200;
            padding-top: 1rem;
            margin-top: 1rem;
        }
    }

    &-header {
        color: $gray-600;
        text-transform: uppercase;
        padding: .5rem 0;
    }

    &-col-number {
        text-align: right;
    }
}

.tr-expanded+tr {
    .cmv-datarow-container {
        position: relative;

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 16px 0 16px;
            border-color: darken($gray-100, 4%) transparent transparent transparent;
            position: absolute;
            left: 50%;
            top: -2px;
        }
    }
}