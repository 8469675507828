.cmv-datagrid {
    display: table;
    width: 100%;

    // CMV DataGrid row
    &-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.2rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid $gray-300;


        &-subtitle {
            color: $gray-600;
            font-size: .85rem;
            display: block;
        }

        // Row: Header
        &-header {
            font-size: 1.4rem;
            font-weight: 700;
            display: table;

            .th {
                display: table-cell;
            }
        }

        // Row: Header Ends

        // DataGrid Cell
        .td {
            display: table-cell;
            vertical-align: middle;
        }
        // DataGrid Cell Ends


        // Row: Footer
        &-footer {}

        // Row: Footer Ends


        // Body row wrapper
        &-wrapper {}

        &-body {
            width: 100%;
            display: table;
        }

        &-collapse {
            width: 100%;
            display: flex;
        }

        // Body row wrapper Ends
    }

    .tr-actions {
        display: flex;
        justify-content: flex-end;
        align-content: center;

        button:disabled {
            color: darken($gray-100, 20%);
        }

        .btn {
            color: $gray-600;
            border-radius: 0;
            border-right: 1px solid $gray-400;

            i {
                font-size: 1.4rem;
                line-height: 1.2;
            }


        }

        >.btn:last-of-type {
            border-right-width: 0;
        }
    }

    .v-align-m {
        vertical-align: middle;
    }

    .h-align-r {
        text-align: right;
    }
}