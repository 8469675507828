// Search for clients field
.cmv-container-dashboard-filter {
    padding: $grid-gutter-width/2 0;

    label {
        display: block;
        padding: 10px 0;
        margin: 0;
        line-height: 18px;
    }

    &,
    .form-control {
        height: auto;
        font-size: 0.875rem;
        line-height: 1.5rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
            font-size: 0.75rem;
        }
        @media (min-width: 1600px) {
            font-size: 0.875rem;
        }
        @media (min-width: 1920px) {
            font-size: 1rem;
        }
    }

    > .container {

        [class*="col"]:not(:last-child) {

            @media (max-width: map-get($grid-breakpoints, md) - 1) {
                margin-bottom: 0.5em;
            }
        }

        .dropdown-cn-timefilter {

            .btn {
                width: 100%;
            }
        }
    }

    .input-group {

        .dropdown {
            display: inline-flex;

            @media (max-width: map-get($grid-breakpoints, md)) {
                width: 100%;
                margin-bottom: .25rem;

                .btn {
                    width: 100%;
                }
            }
        }

        .selectize-control {

            @media (max-width: map-get($grid-breakpoints, md)) {
                width: 100%;
                margin-bottom: .25rem;

                .btn {
                    width: 100%;
                }
            }

            @media (min-width: map-get($grid-breakpoints, md)) {
                max-width: 190px;
            }

            .selectize-input {
                border-top-left-radius: $border-radius !important;
                border-bottom-left-radius: $border-radius !important;

                @media (max-width: map-get($grid-breakpoints, md)) {
                    border-top-right-radius: $border-radius !important;
                    border-bottom-right-radius: $border-radius !important;
                }
            }
        }

        .multi {

            @media (min-width: map-get($grid-breakpoints, md)) {
                width: auto;
                max-width: 420px;
            }
        }

        input.form-control,
        > div[class*="css-"][class*="-container"] {
            flex: 1 1 auto;

            @media (max-width: map-get($grid-breakpoints, md) - 1) {
                border-top-left-radius: $border-radius !important;
                border-bottom-left-radius: $border-radius !important;
            }
        }

        .input-group-prepend {

            .btn {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .btn-group {

                .btn:first-child:not(:only-child) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        .input-group-append {

            .btn:last-child {
                border-top-right-radius: $border-radius !important;
                border-bottom-right-radius: $border-radius !important;
            }

            .btn-group {

                .btn:first-child:not(:only-child) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        .btn-group {
            margin-left: 5px;

            @media (min-width: map-get($grid-breakpoints, md)) {
                margin-left: 10px;
            }
        }

        &-datepicker {

            .react-datepicker-wrapper {
                display: block;
                width: 1%;
                flex: 1 1 auto;

                .form-control {
                    padding-left: 0;
                    padding-right: 0;
                    text-align: center;
                    border-radius: 0;
                }
            }

            .btn {

                @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;
                }
            }
        }
    }

    .btn {
        font-size: 0.75rem;
        line-height: 1.5rem;

        &-primary {
            color: white !important;
        }

        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            font-size: 0.625rem;
        }

        &.dropdown-toggle {
            line-height: 1.5rem;

            &::after {
                margin-left: 0.5rem;
            }

            &-multiline {

                @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;
                    line-height: 1;

                    &::after {
                        vertical-align: 70%;
                    }
                }

                div {
                    display: inline-block;

                    span {

                        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
                            display: block;
                            font-size: 0.75rem;
                            line-height: 1.1;

                            &:first-child {
                                margin-bottom: 0.125rem;
                            }
                        }
                        @media (max-width: map-get($grid-breakpoints, md) - 1), (min-width: 1600px) {
                            display: inline-block;

                            &:last-child::before {
                                content: "-";
                                margin: 0 0.25em;
                            }
                        }
                    }
                }
            }
        }

        &:not(.dropdown-toggle) {

            span {
                display: none;

                @media (min-width: map-get($grid-breakpoints, xl)) {
                    display: inline-block;
                }
            }
        }
    }

    .dropdown-menu {

        @media (min-width: map-get($grid-breakpoints, md)) {
            right: auto;
            left: 0;
        }

        .react-datepicker {
            font-size: 0.75rem;
            user-select: none;
        }

        .react-datepicker__month {
            margin: 0.25rem 0.75rem;
        }

        .react-datepicker__current-month {
            font-size: 1em;
        }

        .react-datepicker__day-name,
        .react-datepicker__day {
            width: 1.9em;
            line-height: 1.9em;
            margin: 0.166em;
        }
    }
}

// Search single result
.cmv-container-client {

    .card {
        border-width: 0;
        background-color: transparent;
        margin-bottom: 0;
        flex-direction: row;

        @media (min-width: map-get($grid-breakpoints, md)) {
            flex-direction: column;
        }

        .card-header {
            border-bottom-color: $gray-500;
            border-bottom-width: 0;

            @media (min-width: map-get($grid-breakpoints, md)) {
                border-bottom-width: 1px;
            }

            i {
                display: none;
            }
        }

        .card-body {
            font-size: .9rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                font-size: 1.2rem;
            }
        }
    }
}


// Search results container
.cmv-container-cam-search-results {
    max-width: 1200px;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .cmv-container-client {
        position: relative;
        margin-bottom: 1rem;
        border: 1px solid $gray-400;
        border-radius: .5rem;
        transition: all .2s;
        overflow: hidden;

        &:hover {
            border-color: $gray-600;
            cursor: pointer;

            .client {

                &-data-basic {
                    background-color: $gray-200;
                }
            }
        }

        .cmv-client-data {

            &-basic {
                padding: 0.5rem;
            }

            &-extended,
            &-contact {
                padding: 0.25rem 0.5rem;

                .card {
                    margin: 0;
                }
            }
        }
    }
}

// Name, Address & Basic Data Block
.cmv-client {

    &-data-basic {
        background-color: $gray-100;
        padding: .25rem;

        .cmv-client {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            @media (min-width: map-get($grid-breakpoints, md)) {
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
            }

            &-name {
                margin-bottom: 0;
                font-size: 1.2rem;
                font-weight: 500;
                color: $gray-700;

                @media (min-width: map-get($grid-breakpoints, md)) {
                    font-size: 1.4rem;
                }
            }

            &-address {
                color: $gray-600;
                margin-bottom: 0;

                @media (max-width: map-get($grid-breakpoints, md)) {
                    font-size: .9rem;

                }
            }
        }

    }

    &-data-extended {
        background-color: $gray-200;
        padding: .25rem;
    }

    &-data-toggle {
        width: 100%;
        line-height: 2;
        flex-basis: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.625rem;
        background: white;
        border-top: 1px solid $gray-300;
        border-radius: 0 0 0.5rem 0.5rem;
        cursor: pointer;
        transition: all .15s linear;

        i {
            margin-left: 0.5rem;
        }

        &:hover {
            color: white;
            background: $primary;
        }

        &-expanded {

            i {
                transform: rotateZ(180deg);
            }
        }
    }
}
// Name, Address & Basic Data Block Ends

// Name, Address & Basic Data Block in Account Screen
.cmv-container-client-selected {
    display: flex;
    flex-direction: column;
    //height: 100%;

    .cmv-container-client {
        border-bottom: 1px solid $gray-200;
    }

    .cmv-client {

        &-data-basic {
            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: 1rem;
            }

            .cmv-client {

                &-name {
                    @media (min-width: map-get($grid-breakpoints, md)) {
                        font-size: 2rem;
                        margin-bottom: .5rem;
                    }
                }
            }
        }

        &-data-extended {
            background-color: $gray-100;

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: 0rem 1rem;
            }
        }
    }

    &-modern {
        background: $white-darker;

        .cmv-form-header,
        .cmv-form-body,
        .cmv-form-footer {

            > .container {

                @media (min-width: map-get($grid-breakpoints, xl)) {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }

        .cmv-form-footer {
            padding: 1rem 0;
            background: white;
            border-color: $gray-300;
        }
    }

    &-full-height {

        @media (min-width: map-get($grid-breakpoints, xl)) {

            @media (min-height: map-get($grid-breakpoints, md)) {
                height: calc(100vh - 5.25rem);
            }
            @media (min-width: 1920px) {
                height: calc(100vh - 6.5rem);
            }
        }
    }

    &-wizard {
        height: auto !important;
        min-height: 100%;
    }

    &-task-details {

        .trouble-ticket-wrapper {
            width: 100%;
            padding: 0;
            margin: 0;
            background: transparent;
            border: none;

            .ticket-notes {
                margin-top: 0;

                p {
                    white-space: normal;
                }
            }
        }

        .order-service-order-wrapper {
            padding: 0.5rem;
            margin: 0.5rem;
            margin-top: 0 !important;
            background: white;
            border: 1px solid $gray-300;
            border-radius: 0.5rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: 1rem;
                margin: 1rem;
            }
            @media (min-width: map-get($grid-breakpoints, xl)) {
                padding: 2rem 1rem;
                margin: 2rem;
            }
        }
    }

    .cmv-container-client-modern {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        flex-shrink: 0;
        margin: 0.5rem;
        background: white;
        border: 1px solid $gray-300;
        border-radius: 0.5rem;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            flex-direction: row;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            margin: 1rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin: 2rem 2rem 1rem;
        }

        & + .cmv-container-client-modern {
            margin-top: 0 !important;
        }

        .cmv-client {

            &-data-basic,
            &-data-extended,
            &-data-contact {
                padding: 0;
                background: transparent;

                .container {
                    padding: 0.5rem;

                    @media (min-width: map-get($grid-breakpoints, md)) {
                        padding: 1rem;
                    }
                }
            }

            &-data-basic {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media (max-width: map-get($grid-breakpoints, sm) - 1) {
                    border-bottom: 1px solid $gray-300;
                }

                @media (min-width: map-get($grid-breakpoints, sm)) {
                    border-right: 1px solid $gray-300;
                }

                .container {

                    @media (min-width: map-get($grid-breakpoints, lg)) {
                        padding: 0.5rem 1rem;
                    }
                }
            }

            &-data-extended,
            &-data-contact {

                @media (min-width: map-get($grid-breakpoints, md)) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: space-between;
                    border-bottom: 1px solid $gray-300;

                    &:last-child {
                        border-bottom: none;
                    }

                    @media (min-width: map-get($grid-breakpoints, lg)) {
                        padding: 0.75rem 1rem;
                    }
                }
            }

            &-data-contact {
                width: 100%;
                font-size: .875rem;
                background: $gray-100;
                border-top: 1px solid $gray-300;

                &:not(.visible) {
                    display: none;
                }

                @media (min-width: map-get($grid-breakpoints, sm)) {
                    display: flex;
                    flex-direction: row;

                    &-primary {
                        display: flex !important;
                    }
                }

                .container {
                    justify-content: flex-start;
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;

                    @media (max-width: map-get($grid-breakpoints, sm) - 1) {
                        flex-direction: column;
                    }
                }

                h5 {
                    margin: 0;
                    font-weight: 400;
                    font-size: .75rem;

                    strong {
                        font-weight: 700;
                        font-size: .875rem;
                    }
                }

                p {
                    margin-top: 0.125rem;
                    margin-bottom: 0.125rem;
                    font-size: .75rem;
                    text-transform: lowercase;

                    &::first-letter {
                        text-transform: uppercase;
                    }

                    @media (min-width: map-get($grid-breakpoints, sm)) {
                        flex-basis: 25%;
                        flex-grow: 0;
                        white-space: nowrap;
                    }
                    @media (min-width: map-get($grid-breakpoints, md)) {
                        margin-right: 0;
                    }
                }
            }

            &-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: stretch;
                width: 100%;
                //margin-bottom: 1rem;

                @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                }

                .cmv-client-status {
                    padding: 0;
                    background: transparent;
                    border: none;
                    outline: none !important;
                }
            }

            &-avatar {
                display: flex;
                flex-shrink: 0;
                align-items: center;
                justify-content: stretch;
                width: 3rem;
                height: 3rem;
                margin-right: 0.5rem;
                text-align: center;
                background: $gray-200;
                border-radius: 100%;

                i {
                    position: relative;
                    top: -2px;
                    margin: auto;
                    font-size: 1.5rem;
                }

                @media (min-width: map-get($grid-breakpoints, md)) {
                    width: 3.75rem;
                    height: 3.75rem;
                    margin-right: 1rem;

                    i {
                        font-size: 2rem;
                    }
                }
                @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {
                    margin-right: 0;
                    margin-bottom: 0.5rem;
                }
                @media (min-width: map-get($grid-breakpoints, xl)) {
                    //width: 4.5rem;
                    //height: 4.5rem;
                    //margin-right: 2rem;
                    //
                    //i {
                    //    font-size: 2.5rem;
                    //}
                }
            }

            &-details {
                //display: flex;
                //flex-direction: column;
                //align-self: center;
                //justify-content: space-between;
            }

            &-name {
                margin-bottom: 0.25rem;
                font-size: 1.125rem;
                font-weight: 700;
                color: inherit;

                @media (min-width: map-get($grid-breakpoints, sm)) {
                    font-size: 1.25rem;
                }
                @media (min-width: map-get($grid-breakpoints, lg)) {
                    margin-bottom: 0.4rem;
                    //font-size: 1.5rem;
                }
            }

            &-address {
                width: 100%;
                margin-bottom: 0;
                color: inherit;
                font-size: 0.75rem;
                line-height: 1.2;
                white-space: normal;

                @media (min-width: 1600px) {
                    font-size: 0.875rem;
                }

                i {
                    margin: 0 0.25rem 0 0;
                }
            }

            &-status {
                position: relative;
                margin: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .status-text {
                    //text-transform: none;
                    //color: inherit;
                    //font-weight: 400;
                    font-size: 0.75rem;
                }

                .status-bar {
                    //    width: 5rem;
                    //    margin-right: 0.5rem;
                    //    text-align: center;
                }

                .dropdown-menu.dropdown-menu-right {

                    @media (min-width: map-get($grid-breakpoints, md)) {
                        right: auto;
                        left: 0;
                    }
                }
            }
        }

        h6 {
            flex-shrink: 0;
            width: 100%;
            margin-bottom: 0.5rem;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: gray;
        }

        p {
            margin: 0 1rem 0 0;
            font-size: 0.875rem;
            line-height: 1.2;

            &:last-child {
                margin-right: 0;
            }

            @media (min-width: map-get($grid-breakpoints, md)) {
                white-space: nowrap;
                line-height: 1;
            }
            @media (min-width: map-get($grid-breakpoints, xl)) {
                flex: 1 1 0px;
                margin: 0;
                font-size: 1rem;
            }

            span {
                font-weight: 600;
                color: $primary;

                @media (max-width: map-get($grid-breakpoints, md) - 1) {
                    display: block;
                    margin-top: 0.25rem;
                }
            }

            i {
                margin-left: 1rem;
            }
        }
    }
}
// Name, Address & Basic Data Block in Account Screen Ends