.cmv-page {
	&-login,
	&-recover-pwd {
    background-color: $white;
		background: url("../../images/background-login.jpg");
		background-position: center;
		background-size: cover;

		main {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: auto;
			margin-bottom: auto;
		}
	}
}