.badge-status {
    display: inline-block;
    border-radius: 5px;
    background-color: $gray-100;
    border: 1px solid $gray-300;
    padding: .15rem .5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-300;
    margin: -1px 0;
    line-height: 1.2;

    &-success {
        // background-color: lighten( $success, 35%);
        background-color: $success;
        border-color: $success;
        // color: $success;
        color: $white;
    }

    &-outline-success {
        border-color: $success;
        color: $success;
    }

    &-warning {
        // background-color: lighten( $warning, 35%);
        background-color: $warning;
        border-color: $warning;
        // color: $warning;
        color: $white;
    }

    &-outline-warning {
        border-color: $warning;
        color: $warning;
    }

    &-danger {
        // background-color: lighten( $danger, 35%);
        background-color: $danger;
        border-color: $danger;
        // color: $danger;
        color: $white;
    }

    &-outline-danger {
        border-color: $danger;
        color: $danger;
    }
}