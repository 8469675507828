// Camvio Cloud Barcode

.barcode {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    > div,
    > input,
    > select {
        flex-grow: 1;
    }

    .btn {
        flex-grow: 0;
        flex-shrink: 0;
        padding-top: 0;
        padding-bottom: 0;
        text-decoration: none !important;
        color: inherit;
        border: none;

        i {
            display: block;
            font-size: 1.75rem;
        }
    }

    &-scanner {

        &-video {

            video {
                height: 0;
            }

            &-started video {
                display: block;
                width: 100% !important;
                height: auto !important;
            }
        }

        &-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            padding: 1rem;
            text-align: center;
            color: white;
            background: rgba(0, 0, 0, 0.5);
            
            p {
                margin: 0 auto;
            }

            i {
                margin: 0 0.25rem;
            }
        }

        &-value {

        }
    }
}