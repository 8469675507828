// Camvio Table

.camvio-table-search{

  border-top-left-radius: .5rem !important;

  .dropdown {
    margin-bottom: 0 !important;

    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: .5rem !important;
      border-bottom-right-radius: .5rem !important;
      border-left: none !important;

      .camvio-table-search-download {
        @media (max-width: map-get($grid-breakpoints, sm)) {
          width: 100%!important;
          display: none;
        }

        .dropdown {
          flex-grow: 1;
        }

        .btn {
          @media (max-width: map-get($grid-breakpoints, sm)) {
            flex-grow: 1
          }
        }
        .extra-filter-select-mobile {
          width: 100% !important;
          display: none;

          @media (max-width: map-get($grid-breakpoints, sm)) {
            display: block;
          }
        }
      }
    }

  }

}

.camvioTable-plan {

  &-extra-filters {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: map-get($grid-breakpoints, sm)) {
    //  flex-direction: row-reverse;
      align-items: flex-start;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
   //   display: none;
    }

    .btn {
      width: 100%;
      height: 32px;
      line-height: 32px;
      padding: 0px 5px;
      margin-left: 0.25rem !important;

      @media (min-width: map-get($grid-breakpoints, sm)) {
        width: 32px;
      }
      @media (min-width: map-get($grid-breakpoints, xl)) {
        width: fit-content;
        height: 38px;
        line-height: 38px;
      }
    }
    .search {
      width: 100%;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        flex-direction: column;
      }
    }
    .extra-filter-select {
      width: 100% !important;
    }
  }

  &-group {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: .5rem;
    transition: all .2s;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border-color: $gray-600;
    }

    .btn-circle {
      background-color: transparent;
      border-color: #adb5bd;
      color: #adb5bd;

      &.active {
        background-color: $cyan;
        color: $gray-100;
        text-decoration: none;
      }
    }

    &-header {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      > .row {

        @media (min-width: map-get($grid-breakpoints, md)) {
          flex-wrap: nowrap;
          justify-content: stretch;
        }

        > .col {

          &:nth-child(2) {
            flex-shrink: 1;
            flex-grow: 1;
          }

          &:last-child {
            flex-shrink: 0;
          }
        }
      }
    }

    &-id {
      margin-bottom: 0.25rem;
      font-weight: 700;
      font-size: 0.75rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      opacity: .5;
    }

    &-detail {
      margin-bottom: 0;
      font-size: 0.75rem;
      color: $gray-600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: map-get($grid-breakpoints, sm) - 1) {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $gray-300;
      }
    }

    &-caption {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }
    }

    &-controls {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: map-get($grid-breakpoints, md) - 1) {
        padding-top: .5rem;
        margin-top: .5rem;
        border-top: 1px solid $gray-300;
      }

      @media (min-width: map-get($grid-breakpoints, md)) {
        height: 100%;
      }

      .btn:not(:last-child) {
        margin: 0 0.25rem 0 0;
      }

      .btn-circle {

        &:disabled {
          color: $gray-500 !important;
          background: transparent !important;
        }
      }
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 1rem;
      color: $gray-800;

      @media (min-width: map-get($grid-breakpoints, md)) {
        font-size: 1.25rem;
      }

      i {
        margin-right: 0.25rem;
      }
    }

    &-status {
      display: block;
      width: auto;
      min-width: 10em;
      padding: 0.25rem 0.5rem;
      text-align: center;
      text-transform: capitalize;
      color: white;
      border-radius: .75rem;
      border: 4px solid $gray-300;
      background-color: rgba($white, .3);

      &.bg-light,
      &.bg-light-with-pulse {
        color: inherit;
      }
    }

    &-number {
      margin-bottom: 0;
      font-size: 0.875rem;
      line-height: 1.2;
      color: $gray-600;
      word-break: break-all;

      &:last-child {
        margin-bottom: 0;
      }

      &:empty {
        display: none;
      }

      span {
        display: inline-block;
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-info {
      margin-bottom: 0.25rem;
      font-size: 0.75rem;

      &:empty {
        display: none;
      }

      > span {
        margin-right: 1rem;
        text-transform: uppercase;

        //&::after {
        //    content: '|';
        //    margin: 0 0.25rem;
        //    color: $gray-600;
        //}

        &:last-child {
          margin-right: 0;

          //&::after {
          //    display: none;
          //}
        }

        span {
          color: $gray-600;
        }
      }
    }

    &-numbers,
    &-details {
      padding: 0 0.5rem;
      background-color: $gray-200;
      border: 1px solid $gray-400;
      border-radius: 0.25rem;

      .row {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-start;
        margin: 0 -0.25rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
          flex-direction: row;
        }
      }

      .col {
        flex-grow: 1;
        padding: 0.5rem 0.25rem;
      }

      h6 {
        font-weight: 700;
        font-size: 0.75rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $gray-600;

        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
          font-size: 0.625rem;
        }
      }

      p {
        display: block;
        margin-bottom: 0.25rem;
        font-size: 0.75rem;
        line-height: 1.2;
        user-select: none;
        cursor: default;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-details {

      div {
        padding: 0.5rem 0;
      }

      h6 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.25rem;
        font-weight: 700;
        font-size: 0.75rem;
        border-bottom: 1px solid $gray-600;
      }

      .cmv-container-log-viewer {
        width: 100%;
        padding: 0.25rem;
        word-wrap: break-word;
        font-size: 0.75rem;

        &::after {
          display: none;
        }

        pre {
          padding: 0.25rem;
          margin: 0;
          color: inherit;
        }
      }
    }
  }
}

.note-btn {
  text-align: center;
  @media (max-width: map-get($grid-breakpoints, md) - 1) {
    text-align: center;
  }
  button {
    i {
      font-size: 32px;
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
      padding: 0px;
      transform: translateY(100%);
    }
  }
}