// Camvio Cloud Data Columns
.data-column {
    &-container {
        // margin-left: -1rem;
        // margin-right: -1rem;
        display: flex;
        flex-direction: column;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            flex-direction: row;
        }

    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 0 1rem;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
        max-width: 50%;
    }

    dl {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    h4 {
        font-size: 1rem;
        border-radius: 5px;
        background-color: $gray-300;
        color: $gray-600;
        padding: .25rem .5rem;
        text-transform: uppercase;
        margin-bottom: .5rem;
    }

    dt,
    dd {
        // display: flex;
        text-align: left;
        width: 100%;
        padding: 0 .5rem;
    }

    dt {
        font-weight: 600;
        color: $gray-500;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            text-align: right;
            width: 50%;
        }
    }

    dd {
        margin-bottom: .25rem;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            width: 50%;
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-bottom: .5rem;
        }
    }

    &-full-width {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }

    &-summary {
        max-width: 100%;

        h4 {
            text-align: center;
            font-size: 1.2rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: .5rem;
            }

        }

        @media (min-width: map-get($grid-breakpoints, md)) {

            dt,
            dd {
                font-size: 1.2rem;
                margin-bottom: .25rem;
            }
        }
    }
}

.container-list-bubble {
    font-size: .875rem;
    width: 100%;
    padding: .5rem 0;
    border-radius: .25rem;
    background-color: darken($gray-200, 2%);
    border: 1px solid darken($gray-200, 7%);

    &:empty {
        display: none;
    }

    // Setup the triangle
    margin-top: .5rem;
    position: relative;

    &:after,
    &:before {
        bottom: 100%;
        left: 5%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: darken($gray-200, 2%);
        border-width: 10px;
        margin-left: -10px;
    }

    &:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: darken($gray-200, 7%);
        border-width: 11px;
        margin-left: -11px;
    }

    // List

    dl {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    dt,
    dd {
        // display: flex;
        text-align: left;
        width: 100%;
        padding: 0 .25rem;
        margin-bottom: 0;
    }

    dt {
        font-weight: 600;
        color: $gray-500;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            text-align: right;
            width: 30%;
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
            text-align: right;
            width: 20%;
        }
    }

    dd {
        @media (min-width: map-get($grid-breakpoints, sm)) {
            width: 70%;
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
            width: 80%;
        }
    }

    &-features {

        dt {
            width: 60% !important;
            text-align: left !important;
        }
        
        dd {
            width: 40% !important;
            text-align: right !important;
        }
    }
}