.cmv-wizard {
    flex-grow: 1;
}

.wizard-container {
    height: 100%;
    padding-top: .5rem;
    animation: slideInFromBottom 0.3s forwards;

    > .container {

        > .cmv-form {
            display: flex;
            flex-direction: column;
            height: calc(100% - 54px);

            > .tab-content {
            }
        }
    }

    // Mid-width container
    &-md {

        .tab-pane {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.wizard-header {
    padding: 1rem;
    text-align: center;
}

.wizard-title {
}

.wizard-content {
    padding: 1rem;
}

.wizard-footer {
    padding: 1rem;
    margin-top: auto;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
    background-color: $gray-100;
    border-top: 1px solid $gray-200;
}

.wizard-navigation {
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid $gray-300;
    overflow: hidden;

    @media (min-width: map-get($grid-breakpoints, xl)) {
        margin: 0.5rem 1rem 0;
    }

    // Legacy
    .nav-tabs .nav-item .nav-link.active {
        color: $white;
        background-color: $link-color;
        box-shadow: none;
    }

    &-nav {
        display: flex;
        flex-wrap: nowrap;
        justify-content: stretch;
        padding: 0;
        margin: 0;
        background: white;
        border: none;
        list-style: none;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &-item {
        flex: 1 1 auto;

        &.active .wizard-navigation-link {
            color: $white !important;
            background: $link-color !important;
            border-color: $link-color !important;

            &::after {
                border-left-color: $link-color !important;
            }
        }

        &.active ~ & .wizard-navigation-link {
            color: $gray-400 !important;
        }

        &:first-child .wizard-navigation-link {
            padding-left: 1rem !important;
        }

        &:last-child .wizard-navigation-link {

            &::before,
            &::after {
                display: none;
            }
        }
    }

    &-link {
        position: relative;
        display: block;
        padding: 0 0.5rem 0 2rem;
        text-decoration: none !important;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 2rem;
        white-space: nowrap;
        border: 0 !important;
        border-radius: 0;
        outline: none;
        cursor: default;
        user-select: none;

        &, &:hover, &:focus {
            color: $link-color;
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
            font-size: 0.875rem;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 1rem;
            line-height: 2.5rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            font-size: 1.25rem;
            line-height: 3rem;
        }

        &::before,
        &::after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 100%;
            display: block;
            width: 0;
            height: 0;
            margin-top: -2rem;
            border-top: 2rem solid transparent;
            border-bottom: 2rem solid transparent;
            border-left-width: 1rem;
            border-left-style: solid;
        }

        &::after {
            z-index: 2;
            border-left-color: white;
        }

        &::before {
            z-index: 1;
            margin-left: 1px;
            border-left-color: $gray-300;
        }
    }
}

.location-map {
    height: 50vh;
}

/* Wizards in Account Details */

.cmv-container-client-modern + .cmv-wizard {
    height: 100%;
    background: $white-darker;

    .wizard-container {
        padding-top: 0;
    }
    .wizard-navigation {
        margin-top: 0;
    }
    .wizard-content {

        @media (min-width: map-get($grid-breakpoints, xl)) {
            padding: 1rem 2rem;
        }
    }
    .wizard-footer {

        .btn {
            min-width: 6rem;
        }
    }
}

