* {
    // overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
}

// Flexbox Setup
html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

body {
    overflow-y: auto;
}

.page-wrapper {
    display: flex;
    min-height: 100vh;
    // height: 100vh;
    // overflow-x: hidden;
}

.page-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 100vw;
}

main {
    flex-grow: 1;
}

header, footer {
    flex-shrink: 0;
}
// Flexbox Setup Ends

// Sidebar Layout
.page-wrapper.page-wrapper-sidebar {

    .cmv-header {
        position: fixed;
        z-index: 1040;
        top: 0;
        left: 0;
        width: 100%;
    }

    .page-content {
        padding-top: 2.75rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
            padding-top: 3.75rem;
        }
        @media (min-width: 1920px) {
            padding-top: 5rem;
        }
    }

    .page-alert {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        color: white;
        background: $alert-bg-warning;

        @media (min-width: map-get($grid-breakpoints, xl)) {
            padding: 0.5rem 2rem;
        }

        p {
            margin-bottom: 0;
        }

        &-text {
            font-weight: 700;
        }

        .btn {
            line-height: 1;
        }
    }
}

// No Sidebar Layout
.page-wrapper.page-wrapper-nosidebar {

    .cmv-header {
        position: sticky;
        z-index: 1040;
        top: 0;
    }
}

// Dashboard
.cmv-dashboard-modern {

    @media (min-width: map-get($grid-breakpoints, md)) {
        min-height: calc(100vh - 5.25rem);
        background: $white-darker;
    }
    @media (min-width: 1920px) {
        min-height: calc(100vh - 6.5rem);
    }

    .page-content-viewas & {

        @media (min-width: map-get($grid-breakpoints, md)) {
            min-height: calc(100% - 2.5rem);
        }
    }
}

// Containers
.cmv-container  {
    padding: 0 $grid-gutter-width/2;
    margin-bottom: 1rem;

    &-dashboard,
    &-table {

        .controls,
        .search {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 1rem;

            .form-control,
            .custom-select {
                display: inline-block;
                vertical-align: middle;
                width: auto;
            }

            label,
            input,
            select {
                margin: 0 0.5rem 0 0;

                &:last-child {
                    margin-right: 0;
                }
            }

            .react-datepicker-wrapper {
                margin-right: 0.5rem;

                label,
                input,
                select {
                    margin-right: 0;
                }
            }

            label {

                @media (max-width: map-get($grid-breakpoints, sm) - 1) {
                    min-width: 3.5rem;
                }
            }

            .btn {
                margin-left: 0.5rem;
                font-size: 1rem;
            }
        }

        .search {

            @media (min-width: map-get($grid-breakpoints, sm)) {
                justify-content: flex-end;
            }

            .form-control {
                width: 8rem;
            }
        }

        .numericInput {

            width: 50px;

            @media (min-width: map-get($grid-breakpoints, sm)) {
                justify-content: flex-end;
            }

        }
    }

    &-dashboard {
        max-width: 1200px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;

        &-tiles {
            max-width: 100%;
            padding: 0;
            margin: 0 auto 1.25rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: 1rem 1rem 0.5rem;
                margin-bottom: 0;
            }
            @media (min-width: map-get($grid-breakpoints, xl)) {
                padding: 2rem 2rem 0.5rem;
            }
        }

        &-stats {
            display: flex;
            flex-direction: row;
            border: none;
            overflow: hidden;

            @media (max-width: map-get($grid-breakpoints, md) - 1) {
                position: fixed;
                z-index: 1;
                bottom: 0;
                left: 0;
                width: 100%;
                background: white;
                box-shadow: 0 0 5px rgba(0, 0, 0, .25);
            }
            @media (min-width: map-get($grid-breakpoints, md)) {
                margin-bottom: -1px;
            }

            .card {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 0.5rem 0;
                text-align: center;
                color: inherit;
                background: transparent;
                border: 1px solid transparent;
                border-bottom: none !important;
                border-radius: 0.5rem 0.5rem 0 0;
                box-shadow: none !important;
                outline: none;

                @media (max-width: map-get($grid-breakpoints, md) - 1) {

                    &-customers.active {color: $accent-customers;}
                    &-tasks.active {color: $accent-tasks;}
                    &-appointments.active {color: $accent-appointments;}
                    &-orders.active {color: $accent-orders;}
                    &-tickets.active {color: $accent-tickets;}
                }

                @media (min-width: map-get($grid-breakpoints, md)) {
                    flex-direction: row;
                    padding: 0.5rem 0.25rem 0.5rem 0.5rem;
                    text-align: left;

                    &:not(.active):hover .card-header {
                        opacity: .8;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        right: 100%;
                        top: 1rem;
                        bottom: 1rem;
                        width: 1px;
                        background: $gray-400;
                    }

                    &:first-child,
                    &.active,
                    &.active + .card {

                        &::before {
                            display: none;
                        }
                    }

                    &.active {
                        background: white;
                        border-color: $gray-300;
                        cursor: default;
                    }

                    &.active:not(:first-child),
                    &.active + .card {

                        &::before {
                            bottom: 0;
                            right: 100%;
                            position: absolute;
                            top: auto;
                            left: auto;
                            display: block;
                            width: 1rem;
                            height: 1rem;
                            border-radius: 1rem;
                            background: transparent;
                            border-right: 1px solid $gray-300;
                            border-bottom: 1px solid $gray-300;
                            box-shadow: 0.5rem 0.5rem 0 0 white;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 1px;
                            right: calc(100% + 1px);
                            left: auto;
                            top: auto;
                            width: 1rem;
                            height: 1rem;
                            border-radius: 0 0 0.5rem 0;
                            background: $white-darker;
                        }
                    }

                    &.active + .card {

                        &::before {
                            bottom: 0;
                            left: -2px;
                            right: auto;
                            border-right: none;
                            border-left: 1px solid $gray-300;
                            box-shadow: -0.5rem 0.5rem 0 0 white;
                        }

                        &::after {
                            left: -1px;
                            right: auto;
                            border-radius: 0 0 0 0.5rem;
                            background: $white-darker;
                        }
                    }
                }

                @media (min-width: map-get($grid-breakpoints, md) * 2) {
                    padding: 1rem 0.25rem 1rem 1rem;
                }

                .card-indicator {
                    position: absolute;
                    top: 0.25rem;
                    left: 0.25rem;

                    &-customers {color: $accent-customers;}
                    &-tasks {color: $accent-tasks;}
                    &-appointments {color: $accent-appointments;}
                    &-orders {color: $accent-orders;}
                    &-tickets {color: $accent-tickets;}
                }

                .card-header {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    text-align: center;
                    background: transparent;
                    border: none;
                    border-radius: 100px;

                    @media (min-width: map-get($grid-breakpoints, md)) {
                        flex-shrink: 0;
                        flex-grow: 0;
                        width: 1.75rem;
                        height: 1.75rem;
                        color: white;
                        transition: opacity .2s linear;

                        &-customers {background: $accent-customers;}
                        &-tasks {background: $accent-tasks;}
                        &-appointments {background: $accent-appointments;}
                        &-orders {background: $accent-orders;}
                        &-tickets {background: $accent-tickets;}
                    }
                    @media (min-width: map-get($grid-breakpoints, xl)) {
                        width: 2rem;
                        height: 2rem;
                    }
                    @media (min-width: map-get($grid-breakpoints, md) * 2) {
                        width: 3rem;
                        height: 3rem;
                    }
                    @media (min-width: 1920px) {
                        width: 5rem;
                        height: 5rem;
                    }

                    i {
                        font-size: 0.875rem;
                        line-height: 1;

                        @media (min-width: map-get($grid-breakpoints, md) * 2) {
                            font-size: 1.5rem;
                        }
                        @media (min-width: 1920px) {
                            font-size: 2.5rem;
                        }
                    }
                }

                .card-body {
                    position: relative;
                    z-index: 1;
                    padding: 0;

                    @media (min-width: map-get($grid-breakpoints, md)) {
                        padding-left: 0.5rem;
                    }
                    @media (min-width: map-get($grid-breakpoints, md) * 2) {
                        padding-left: 1rem;
                    }

                    .card-title {
                        margin: 0;

                        @media (max-width: map-get($grid-breakpoints, md) - 1) {
                            margin-top: 0.25rem;
                            text-transform: uppercase;
                            font-size: 7px;
                            font-weight: 400;
                            line-height: 1;
                        }
                        @media (min-width: map-get($grid-breakpoints, md) / 2) {
                            font-size: 8px;
                        }
                        @media (min-width: map-get($grid-breakpoints, sm)) {
                            font-size: 0.625rem;
                        }
                        @media (min-width: map-get($grid-breakpoints, md)) {
                            margin-bottom: 0.25rem;
                        }
                        @media (min-width: map-get($grid-breakpoints, xl)) {
                            font-size: 0.875rem;
                        }
                        @media (min-width: map-get($grid-breakpoints, md) * 2) {
                            margin-bottom: 0.5rem;
                            font-size: 1rem;
                        }
                        @media (min-width: 1920px) {
                            font-size: 1.25rem;
                        }
                    }

                    .card-text {
                        display: none;

                        @media (min-width: map-get($grid-breakpoints, md)) {
                            display: block;
                            text-transform: uppercase;
                            font-size: 7px;
                            font-weight: 400;
                            line-height: 1.1;
                            letter-spacing: 0.05rem;
                            color: gray;
                        }
                        @media (min-width: map-get($grid-breakpoints, xl)) {
                            font-size: 0.625rem;
                        }
                        @media (min-width: 1920px) {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }

        &-search .cmv-container-cam-client-search {
            border-radius: $border-radius-lg;
        }

        .cmv-container-ticket-search-results {
            padding: 0 1rem;

            .ticket-wrapper {
                margin: 0 0 1rem;
            }
        }

        .cmv-container-appointment-calendar-search-results {
            padding: 0 1rem;
        }

        .cmv-container-appointment-map-search-results {
            padding: 0 1rem;
        }

        .cmv-container-appointment-list-search-results {
            padding: 0 1rem 1rem;
        }

        &-recent .cmv-container-cam-search-results,
        .cmv-container-order-search-results {
            max-width: none;
            padding: 0 1rem 1rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding-bottom: 0;
            }

            .cmv-container-client,
            .order-wrapper {
                margin-top: 0;
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 1rem;
                }

                @media (max-width: map-get($grid-breakpoints, md) - 1) {

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .tab-pane {
            background: white;

            @media (min-width: map-get($grid-breakpoints, md)) {
                border: 1px solid $gray-300;
                border-radius: 0.5rem;

                &:first-child {border-top-left-radius: 0;}
                &:last-child {border-top-right-radius: 0;}

                &:empty {display: none;}
            }

            &-caption {

                @media (max-width: map-get($grid-breakpoints, md) - 1) {
                    padding: 1rem;
                    background: $white-darker;
                    border-bottom: 1px solid $gray-300;
                }
                @media (min-width: map-get($grid-breakpoints, md)) {
                    display: none;
                }
            }

            &-title {
                margin-bottom: 0.25rem;
                font-size: 1.25rem;
            }

            &-text {
                margin: 0;
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1.1;
                letter-spacing: 0.05rem;
                color: gray;
            }

            .cmv-container-table {
                padding: 0;
                margin: 0;
            }

            .cmv-table {
                margin-bottom: 0;

                @media (min-width: map-get($grid-breakpoints, sm)) {

                    tr:last-child td {

                        &:first-child {border-radius: 0 0 0 0.5rem;}
                        &:last-child {border-radius: 0 0 0.5rem 0;}
                    }
                }
            }

            .css-1l55k88-style-Loader {
                margin-bottom: 2rem;
            }
        }
    }

    &-table {
        width: 100%;
        margin: .5rem 0;
        padding: .5rem 0;

        &-invoices {

            @media (min-width: map-get($grid-breakpoints, sm)) {

                tbody tr:last-child td:last-child .tr-actions .dropdown-menu {
                    top: auto;
                    bottom: 100%;
                }
            }

            .table-responsive {
                padding: 0.75rem 0;

                @media
                (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1),
                (min-width: map-get($grid-breakpoints, md) * 2) {
                    padding: 0;
                    overflow: visible;
                }
            }

            .cmv-table td .badge {
                min-width: 4rem;
            }
        }
    }

    &-subpanel {
        width: 100%;
        border: 1px solid $gray-300;
        padding: 0 1rem 1rem 1rem;
        background-color: $gray-100;
        margin-bottom: 1rem;
        h5 {
            margin: 0 -1rem 1rem -1rem;
            padding: .5rem 1rem;
            color: $gray-700;
            background-color: $gray-300;
            font-size: 1.1rem;
            font-weight: 500;
            text-transform: uppercase;
        }
        .container-matched-data {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
            border-top: 1px solid $gray-200;
            border-bottom: 1px solid $gray-200;
            dl {
                margin-bottom: 0;
            }
        }
    }

    &-chart {
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
        &-md {
            width: 400px;
        }
        &-xs {
            width: 300px;
        }
    }

    &-log-viewer {
        width: 100%;
        font-family: monospace;
        line-height: 1.2;
        color: $gray-100;
        background-color: $gray-700;
        padding: .5rem;
        border-radius: 5px;
        position: relative;

        &:after {
            cursor: pointer;
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            content: "\f328";
            font-family: $icon-font;
            font-size: 1.25rem;
            padding: .25rem .5rem;
            border-radius: 5px;
            background-color: rgba($white, .3);
        }
    }

    &-task {}

    &-task-single {
        padding: 1rem 0;
        h2 {

        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        height: 100%;

        >.cmv-form {
            display: flex;
            flex-direction: column;
            height: 100%
        }

        .cmv-form-header {}

        .cmv-form-body {
            flex-grow: 1;
        }

        .cmv-form-footer {
            border-top: 1px solid $gray-200;
            background-color: $gray-100;
            padding: 1rem;
        }

        .container {
            margin-left: auto;
            margin-right: auto;
            max-width: 1200px;
        }
    }
}

.container {
    max-width: $cmv-bs-container-max-width;
    // Structural containers

    &-bg-100 {
        margin-left: -15px;
        margin-right: -15px;
        padding: .1rem 15px;
        @media (min-width: map-get($grid-breakpoints, md)) {
            padding-top: .25rem;
            padding-bottom: .25rem;
        }
        background-color: $gray-100;
        border-bottom: 1px solid $gray-300;
    }

    &-bg-200 {
        margin-left: -15px;
        margin-right: -15px;
        padding: .1rem 15px;
        @media (min-width: map-get($grid-breakpoints, md)) {
            padding-top: .25rem;
            padding-bottom: .25rem;
        }
        background-color: $gray-200;
        border-bottom: 1px solid $gray-400;
    }

    &-bg-300 {
        margin-left: -15px;
        margin-right: -15px;
        padding: 1rem 15px;
        background-color: $gray-300;
        border-bottom: 1px solid $gray-500;
    }

    &-bg-400 {
        margin-left: -15px;
        margin-right: -15px;
        padding: 1rem 15px;
        background-color: $gray-400;
        border-bottom: 1px solid $gray-600;
    }

    &-tabs {
        padding: 1rem 0;
        .nav-pills {
            border-bottom: 1px solid $gray-200;
        }
    }
    &-client-balance {

    }
    &-client-notes {

    }
    &-accordion-content {
        &:last-of-type {
            @media (max-width: map-get($grid-breakpoints, xl)) {
                h3.collapsed {
                    margin-bottom: 0;
                    border-bottom-color: transparent;
                }
            }
        }
    }
    // Structural containers Ends
}

/* Additional Column Sizes */

@media (min-width: 1600px) {
    .col-xxl-1 {flex: 0 0 8.33333%; max-width: 8.33333%;}
    .col-xxl-2 {flex: 0 0 16.66667%; max-width: 16.66667%;}
    .col-xxl-3 {flex: 0 0 25%; max-width: 25%;}
    .col-xxl-4 {flex: 0 0 33.33333%; max-width: 33.33333%;}
    .col-xxl-5 {flex: 0 0 41.66667%; max-width: 41.66667%;}
    .col-xxl-6 {flex: 0 0 50%; max-width: 50%;}
    .col-xxl-7 {flex: 0 0 58.33333%; max-width: 58.33333%;}
    .col-xxl-8 {flex: 0 0 66.66667%; max-width: 66.66667%;}
    .col-xxl-9 {flex: 0 0 75%; max-width: 75%;}
    .col-xxl-10 {flex: 0 0 83.33333%; max-width: 83.33333%;}
    .col-xxl-11 {flex: 0 0 91.66667%; max-width: 91.66667%;}
    .col-xxl-12 {flex: 0 0 100%; max-width: 100%;}
}

@media (min-width: 1920px) {
    .col-xxxl-1 {flex: 0 0 8.33333%; max-width: 8.33333%;}
    .col-xxxl-2 {flex: 0 0 16.66667%; max-width: 16.66667%;}
    .col-xxxl-3 {flex: 0 0 25%; max-width: 25%;}
    .col-xxxl-4 {flex: 0 0 33.33333%; max-width: 33.33333%;}
    .col-xxxl-5 {flex: 0 0 41.66667%; max-width: 41.66667%;}
    .col-xxxl-6 {flex: 0 0 50%; max-width: 50%;}
    .col-xxxl-7 {flex: 0 0 58.33333%; max-width: 58.33333%;}
    .col-xxxl-8 {flex: 0 0 66.66667%; max-width: 66.66667%;}
    .col-xxxl-9 {flex: 0 0 75%; max-width: 75%;}
    .col-xxxl-10 {flex: 0 0 83.33333%; max-width: 83.33333%;}
    .col-xxxl-11 {flex: 0 0 91.66667%; max-width: 91.66667%;}
    .col-xxxl-12 {flex: 0 0 100%; max-width: 100%;}
}