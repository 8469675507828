.order {

    &-wrapper {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: $gray-100;
        border: 1px solid $gray-400;
        border-radius: .5rem;
        transition: all .2s;

        &:first-child {
            margin-top: 1rem;
        }
        &:last-child {
            margin-bottom: 1rem;
        }

        &:hover {
            border-color: $gray-600;
        }

        &-bulk-selection {
            cursor: pointer;

            .order-header > *,
            .order-body {
                opacity: .5;
                pointer-events: none;
                user-select: none;
            }
        }

        &-bulk-selection.order-wrapper-bulk-selection-disabled {
            border-color: $gray-400 !important;
            cursor: not-allowed !important;

            .order-select {
                opacity: 0;
                visibility: hidden;
            }
        }

        &-bulk-selection.order-wrapper-bulk-selection-selected {

            .order-header > *,
            .order-body {
                opacity: 1;
            }
        }

        .btn-circle {
            background-color: transparent;
            border-color: $gray-500;
            color: $gray-500;

            &.active {
                background-color: $cyan;
                color: $gray-100;
                text-decoration: none;
            }
        }
    }

    &-select {
        margin-right: 0.5rem;
    }

    &-header {
        flex-grow: 1;

         > [class*="col"] {

            @media (min-width: 1600px) {

                &:nth-child(1) {flex-basis: 20%; max-width: 20%;}
                &:nth-child(2) {flex-basis: 48%; max-width: 48%;}
                &:nth-child(3) {flex-basis: 20%; max-width: 20%;}
                &:nth-child(4) {flex-basis: 12%; max-width: 12%;}
            }
        }
    }

    &-body {

        .services-summary {

            background-color: $white;

            .services-summary-body {
                display: block;
                height: unset;
                max-height: 300px;
                overflow-y: scroll;

                h4 {
                    cursor: unset;
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }

    &-title,
    &-date {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // @media (min-width: map-get($grid-breakpoints, md)) {
        //     flex-direction: column;
        //     justify-content: flex-start;
        // }

        p {
            font-weight: 500;
            color: $gray-700;
            margin-bottom: .25rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            @media (min-width: map-get($grid-breakpoints, md)) {
                font-size: 1.25rem;
            }
        }
    }

    &-info {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        font-size: 0.75rem;
        color: $gray-600;
        border-bottom: 1px solid $gray-300;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            border-bottom-width: 0;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        > span {
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5em;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:last-child {
                margin-right: 0;
            }

            &.order-sales-agent {
                white-space: normal;

                .btn-outline-secondary {
                    cursor: pointer;
                }
            }

            i {
                margin-right: 0.25em;
            }
        }
    }

    .order-service-order-wrapper &-info {

        > span {
            white-space: normal;
        }
    }

    &-title {
        height: 100%;
        justify-content: flex-start;

        @media (max-width: map-get($grid-breakpoints, md) - 1) {
            margin-bottom: 0.5rem;
        }

        &-name {

            button {
                padding: 0;
                margin: 0;
                text-decoration: underline;
                color: inherit !important;
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-address {
            font-size: 0.875rem;

            @media (min-width: map-get($grid-breakpoints, xl)) {
                margin-bottom: 0 !important;
            }
        }

        &-service {
            margin: 0.25rem 0;

            @media (min-width: map-get($grid-breakpoints, md)) {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 0;
                margin-bottom: 0.25rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            p {

                @media (min-width: map-get($grid-breakpoints, md)) {
                    font-size: 1.125rem;
                }
            }
        }

        &-date {
            font-size: 0.875rem;

            @media (min-width: 1600px) {

                &:empty {
                    display: none;
                }

                &::before {
                    content: '|';
                    margin: 0 0.125rem 0 0.25rem;
                }
            }
        }

        i {
            width: 1.25rem;
            text-align: center;
            font-size: 0.875rem;
        }
    }

    &-status {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media (min-width: map-get($grid-breakpoints, md)) {
            text-align: center;
        }
        @media (min-width: 1600px) {
            text-align: right;
        }

        .progress {
            height: 34px;
            border-radius: .75rem;
            border: 4px solid $gray-300;
            background-color: rgba($white, .3);
            margin-bottom: 0.125rem;

            .progress-bar {
                background-color: $white;
            }

            .btn {
                border-radius: .5rem;
                height: 26px;
                border-width: 2px;
                padding-top: .1rem;
                padding-bottom: .1rem;
            }
        }

        .text-muted {
            padding: 0 0.25rem;
            font-size: .75rem;
            line-height: 1.2;
        }
    }

    &-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            height: 100%;
        }

        .btn:not(:last-child) {
            margin: 0 0.25rem 0 0;

            @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
                margin: 0 0 0.25rem 0;
            }
        }

        .btn-circle {

            &:disabled {
                color: $gray-500 !important;
                background: transparent !important;
            }
        }
    }

    &-source {
        margin-bottom: 0.25rem;
        font-weight: 700;
        font-size: 0.75rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        opacity: .5;
    }

    &-service-order-status {
        margin-bottom: .25rem;
    }

    &-toolbar {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: 1599px) {
            justify-content: flex-end;
        }

        .btn-group-sm > .btn {

            @media (max-width: 1599px) {
                font-size: 0.75rem;
            }
            @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
                line-height: 1;
            }
        }

        .btn:disabled {
            cursor: not-allowed;
        }
    }

    &-body-content {
    }

    &-service-order-wrapper {
        padding: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: $gray-200;
        border: 1px solid $gray-400;
        border-radius: 0.5rem;

        &:last-of-type{
            margin-bottom: 0;
        }

        .order-header {
        }

        .order-body {
            margin-top: .5rem;
            border-top: 1px solid $gray-300;

            h6 {
                font-size: 1.1rem;
                padding-bottom: .25rem;
                margin-top: 1rem;
                margin-bottom: .25rem;
                color: darken($gray-500, 10%);
                border-bottom: 1px dotted $gray-500;
            }
            p {
                margin-bottom: 0;
            }
        }

        .cmv-container-log-viewer {
            margin-bottom: 1rem;
        }

        .cmv-container-user-tasks {
            padding: 0;
            margin: 0 -1rem;
            margin-bottom: -1rem;

            &:empty {
                display: none;
            }

            .cmv-container-table,
            .cmv-table {
                padding-bottom: 0;
                margin-bottom: 0;

                tr:last-child td {

                    &:first-child {border-radius: 0 0 0 0.5rem;}
                    &:last-child {border-radius: 0 0 0.5rem 0;}
                }
            }
        }
    }

    &-wrapper:last-child &-service-order-wrapper {

        .cmv-container-user-tasks {

            @media (min-width: map-get($grid-breakpoints, sm)) {

                tr:last-child:not(:first-child) td .tr-actions .dropdown-menu {
                    top: auto;
                    bottom: 100%;
                }
            }

            @media (min-width: map-get($grid-breakpoints, md)) {

                tr:nth-last-child(2):not(:first-child) td .tr-actions .dropdown-menu {
                    top: auto;
                    bottom: 100%;
                }
            }
        }
    }
}