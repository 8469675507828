// Camvio Cloud Trouble Ticket
.trouble-ticket-wrapper {
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: .75rem;
    padding: 1rem;
    margin-top: .75rem;
    margin-bottom: .75rem;

    .btn-circle {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
    }

    .tt-header {
        margin-bottom: .5rem;
    }

    .tt-source {
        padding-bottom: 1rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $gray-300;
    }

    .tt-meta {
        font-size: .875rem;
        >span {
            display: block;
        }
    }

    .tt-toolbar {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .tt-status {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // height: 100%;
        margin-bottom: .5rem;

        .progress {
            height: 34px;
            border-radius: .75rem;
            border: 4px solid $gray-300;
            background-color: rgba($white, .3);
            margin-bottom: 0;

            .progress-bar {
                background-color: $white;
            }

            .btn {
                border-radius: .5rem;
                height: 26px;
                border-width: 2px;
                padding-top: .1rem;
                padding-bottom: .1rem;
            }
        }
    }

    .tt-issue {

        .problem,
        .cause,
        .escalation,
        .resolution {
            border-radius: .25rem;
            border: 1px solid;
            border-left-width: 25px;
            padding: .25rem;
            margin-bottom: .5rem;
            font-size: .875rem;

            &:before {
                color: $white;
                font-family: $icon-font;
                text-align: center;
                position: absolute;
                left: 38px;
            }

            p:last-of-type {
                margin-bottom: 0
            }

            .title {
                font-size: 1rem;
                font-weight: 500;
                color: $gray-700;
                text-transform: uppercase;

                .toolbar {
                    float: right;
                }
            }

            .body {
                padding-top: .25rem;
                padding-bottom: .25rem;
            }

            .footer {
                padding-top: .25rem;
                border-top-width: 1px;
                border-top-style: solid;
            }
        }

        // Problem Specifics
        .problem {
            background-color: lighten($danger, 48%);
            border-color: $danger;

            &:before {
                content: '\f06a'; // Exclamation mark Icon
                font-weight: 900;
            }

            .title {
                font-size: 1.25rem;
                color: $danger;
            }

            .footer {
                border-top-color: rgba($danger, .5);
            }
        }

        // Cause Specifics
        .cause {
            background-color: $gray-200;
            border-color: $gray-500;

            &:before {
                content: '\f02d'; // Book Icon
                font-weight: 900;
            }

            .title {
                color: $gray-700;
            }

            .footer {
                border-top-color: rgba($gray-500, .5);
            }
        }

        // Escalation Specifics
        .escalation {
            background-color: lighten($warning, 40%);
            border-color: $warning;

            &:before {
                content: '\f56e'; // Export Icon
                font-weight: 900;
            }

            .title {
                color: $warning;
            }

            .body {
                .task {
                    border-left: 1px solid $warning;
                    position: relative;
                    padding-left: 5px;
                    margin-top: .25rem;
                    margin-left: 20px;
                    margin-bottom: .25rem;

                    &:before {
                        color: $warning;
                        font-family: $icon-font;
                        text-align: center;
                        position: absolute;
                        left: -20px;
                        content: '\f0ae'; // Task list Icon
                        font-weight: 900;
                    }

                    .task-footer {
                        font-size: .75rem;
                        color: $gray-600;
                    }

                }
            }

            .footer {
                border-top-color: rgba($warning, .5);
            }
        }

        // Resolution Specifics
        .resolution {
            background-color: lighten($success, 45%);
            border-color: $success;

            &:before {
                content: '\f058'; // Check icon
            }

            .title {
                color: $success;
            }

            .footer {
                border-top-color: rgba($success, .5);
            }
        }

        > div:last-of-type {
            margin-bottom: 0;
        }
    }
}