.client-notes {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-radius: 5px;
        margin-bottom: 1rem;
        padding: .5rem;
        border-bottom: 1px dotted $gray-400;

        &:last-child {
            margin-bottom: 0;
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            padding-top: .25rem;
            padding-bottom: .5rem;
            border-bottom: 1px dotted $gray-400;
            margin-bottom: .5rem;
            .client-note-title {
                color: $gray-600;
                font-size: .85rem;
            }
            .client-note-toolbar {
                margin-left: auto;
            }
        }
        .footer {
            border-top: 1px dotted $gray-400;
            margin-top: .5rem;
            padding-top: .25rem;
        }


        .client-note-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            text-align: center;
            color: white;
            background: #55C1E7;
            border-radius: 100%;
            overflow: hidden;
        }

        &:nth-of-type(odd) {
            background-color: $gray-100;
            .client-note-img {
                float: left;
            }
            .client-note-body {
                margin-left: 60px;
            }
        }

        &:nth-of-type(even) {
            background-color: $gray-200;
            .client-note-img {
                float: right;
            }
            .client-note-body {
                //text-align: right;
                margin-right: 60px;
            }
        }

        .client-note-status {
            margin-left: 0.5rem;

            //@media (min-width: map-get($grid-breakpoints, xl)) {
            //    opacity: 0;
            //}
        }

        //&:hover .client-note-status {
        //    opacity: 1 !important;
        //}

        .client-note-body p {
            margin: 0;
            color: $gray-800;
        }
    }

    &-no-img {
        li {
            &:nth-of-type(odd) {
                .client-note-body {
                    margin-left: 0;
                }
            }
            &:nth-of-type(even) {
                .client-note-body {
                    margin-right: 0;
                }
            }
        }
    }

    &-h-constrained {
        max-height: 300px;
        overflow-y: scroll;
    }
}


