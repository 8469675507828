@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@-webkit-keyframes warning-fade {
   0% {background: $warning;}
   20% {background: none;}
   40% {background: $warning;}
   60% {background: none;}
   80% {background: $warning;}
   100% {background: none;}
}

@keyframes warning-fade {
   0% {background: $warning;}
   20% {background: none;}
   40% {background: $warning;}
   60% {background: none;}
   80% {background: $warning;}
   100% {background: none;}
}

@-webkit-keyframes indigo-fade {
   0% {background: lighten($indigo, 40%);}
   20% {background: none;}
   40% {background: lighten($indigo, 40%);}
   60% {background: none;}
   80% {background: lighten($indigo, 40%);}
   100% {background: none;}
}

@keyframes indigo-fade {
   0% {background: lighten($indigo, 40%);}
   20% {background: none;}
   40% {background: lighten($indigo, 40%);}
   60% {background: none;}
   80% {background: lighten($indigo, 40%);}
   100% {background: none;}
}

// Pulses
@keyframes pulse-success {
  0% {
    background-color: $success;
  }
  50% {
    background-color: lighten($success, 15%);
  }
  100% {
    background-color: $success;
  }
}

@keyframes pulse-warning {
  0% {
    background-color: $warning;
  }
  50% {
    background-color: lighten($warning, 15%);
  }
  100% {
    background-color: $warning;
  }
}

@keyframes pulse-danger {
  0% {
    background-color: $danger;
  }
  50% {
    background-color: lighten($danger, 15%);
  }
  100% {
    background-color: $danger;
  }
}

@keyframes pulse-light {
  0% {
    background-color: $gray-300;
  }
  50% {
    background-color: lighten($gray-300, 15%);
  }
  100% {
    background-color: $gray-300;
  }
}