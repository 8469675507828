.card-plan {

    &-expanded {
        border-color: $gray-500 !important;
    }
}

.bundle-wrapper,
.plan-wrapper,
.service-wrapper {

    .dropdown-item {
        user-select: none;
        line-height: 1.25;

        &[disabled] {
            color: $gray-900 !important;
            background: transparent !important;
            opacity: .5;
            cursor: not-allowed;
        }
    }
}

.bundle-wrapper,
.plan-wrapper {
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }

    h4 {
        padding-bottom: .25rem;
        border-bottom: 1px solid $gray-600;
    }
}

.plan-wrapper {
    padding: .5rem;
    margin-bottom: 0.5rem;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 5px;

    &-expanded {
        padding-bottom: 0;
        border-color: $gray-500;
    }

    &-historical {
        opacity: .6;
        transition: .1s opacity linear;

        &:hover {
            opacity: 1;
        }

        &::before {
            content: "Historical";
            display: block;
            margin: calc(-0.5rem - 1px) calc(-0.5rem - 1px) 0.5rem;
            font-size: 0.75rem;
            text-align: center;
            color: white;
            background: $gray-500;
            border-radius: 0.25rem 0.25rem 0 0;
        }
    }
}

.bundle-header,
.plan-header {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-size: 1.2em;

    &-centered {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .plan-toolbar {
            margin-bottom: 0 !important;
        }
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: row;
        justify-content: space-between;
    }

    .bundle-title {
        color: $gray-800;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: .25rem;
    }

    .plan-title {
        color: $gray-800;
        font-weight: 500;
        //margin-bottom: .5rem;
    }

    .plan-subtitle {
        font-size: .75rem;
        font-weight: 400;
        margin-bottom: 0;

        &-aligned {
            padding-left: 1.8rem;

            span {
                font-weight: 500;
            }
        }
    }

    .plan-toolbar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        margin-left: auto;
        font-size: .9rem;
        // line-height: 2.1;
        color: $gray-600;
        margin-bottom: .5rem;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            margin-bottom: 0;
        }

        .plan-title {
            margin-bottom: 0;
        }

        > span {
            margin: .15rem;
            line-height: 1;

            &:last-child {
                margin-right: 0;
            }
        }

        > button {
            margin-left: .5rem;
        }

        .badge {
            font-weight: normal;
            line-height: 1.3;
            font-size: 1rem;
        }
    }
}

.plan-body {
    //background-color: $gray-100;
    //margin-bottom: 1rem;
    //padding: .5rem;
    //border-radius: 5px;

    &:last-of-type {
        margin-bottom: 0;
    }

    h6 {
        display: flex;
        flex-direction: column;

        @media (min-width: map-get($grid-breakpoints, md)) {
            flex-direction: row;
            justify-content: space-between;
        }

        font-size: 1.2rem;
        border-bottom: 1px solid $gray-200;
        padding-bottom: .5rem;

        span {
            font-size: .75rem;
            line-height: 2.4;
            color: $gray-600;
            text-transform: uppercase;
        }
    }
}

.plan-section {
    border-radius: 5px;
    background-color: $gray-100;
    padding: .5rem;

    h5 {
        padding: .5rem .5rem .25rem .5rem;
        font-size: 1.1rem;
        font-weight: 500;
        color: $gray-700;
        text-transform: uppercase;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: $gray-200;
        margin-bottom: 0;

        +.package-wrapper {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

.bundle-description {
    color: $gray-600;
    margin-bottom: 1rem;
}

.bundle-body {
    background-color: $gray-100;
    margin-bottom: 1rem;
    padding: 1rem .5rem;
    border-radius: 5px;

    &:last-of-type {
        margin-bottom: 0;
    }

    i {
        font-size: 1.6rem;
        color: $gray-700;

        &:not(last-child) {
            margin-right: .5rem;
        }
    }

    p {
        color: $gray-800;
        margin-bottom: 0
    }

    .row {
        align-items: center;
        // margin-bottom:
    }

    .bundle-price {
        text-align: right;

        .lead {
            font-weight: 500;
            font-size: 1.2rem;
        }
    }

    .bundle-details {

        // Add padding and margin to inner container to fix BS's collapse glitch
        .row {
            margin-top: 1rem;
            border-top: 1px solid $gray-200;
            align-items: flex-start;

            div[class^="col-"] {
                padding-top: 1rem;
            }
        }

        h5 {
            color: $gray-700;
        }

        p {
            color: $gray-600;
        }
    }
}

.plan-description,
.services-wrapper {
    margin-top: .5rem;
}

.plan-description {
    position: relative;
    background-color: $gray-100;
    border: 1px solid $gray-200;
    border-radius: 5px;
    padding: .5rem .5rem .5rem 1.8rem;

    &:empty {
        display: none;
    }

    &:before {
        content: "\f05a";
        position: absolute;
        left: 0;
        width: 1.8rem;
        text-align: center;
        font-weight: 900;
        font-family: $icon-font;
        color: $gray-500;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.service-wrapper {
    // position: relative;
    //margin-bottom: .5rem;
    margin: 0 -0.5rem;
    padding: 0.5rem;
    //border-radius: 5px;
    //border: 2px solid $gray-300;

    .card-plan &:first-child {
        margin-top: 0.25rem !important;
    }

    .card-plan &:last-child {
        margin-bottom: -0.5rem !important;
        border-radius: 0 0 5px 5px;
    }

    .services-wrapper > &:last-child,
    .pagination-results:last-child &:last-child {
        border-radius: 0 0 5px 5px;
    }

    // Folder tree-like offset and marker:

    // margin-left: 25px;
    // &:before {
    //  position: absolute;
    //  left: -21px;
    //  top: calc(50% - ((100% / 2) - 5px));
    //  content: "";
    //  width: 20px;
    //  height: calc((100% / 2) - 5px);
    //  background-color: transparent;
    //  border-bottom-left-radius: 0.25rem;
    //  border-left: 3px solid $gray-300;
    //  border-bottom: 3px solid $gray-300;
    // }

    &:nth-of-type(odd) {
        background-color: $gray-100;
    }

    &:nth-of-type(even) {
        background-color: darken($gray-100, 4%);
    }

    &:last-of-type {
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }

    h4 {
        color: $gray-800;
    }

    &-inactive {
        .service-header {
            > * {
            opacity: .65;}
        }
    }

    &-description {
        color: $gray-600;
    }

    .service-header {
        display: flex;
        flex-direction: column;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .service-title {
            //font-size: 1.125rem;
            line-height: 1.1;
            color: $gray-800;
        }

        .service-body-toggler {
            cursor: pointer;

            // Open Icon
            &:before {
                content: "\f078";
                display: inline-block;
                width: 1.8em;
                margin-right: 0.2rem;
                text-align: center;
                font-weight: 900;
                font-size: 0.875rem;
                font-family: $icon-font;
                color: $gray-700;
            }

            // Closed Icon
            &.service-body-closed {

                &:before {
                    content: "\f054";
                }
            }
        }

        .service-toolbar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            color: $gray-600;
            margin-left: auto;

            @media (min-width: map-get($grid-breakpoints, sm)) {
                margin-left: auto;
                justify-content: flex-end;
            }

            .btn-primary {
                text-transform: uppercase;
                cursor: pointer;
            }

            >i {
                margin-right: .25rem;

                &:after {
                    font-weight: normal;
                    content: "|\2005";
                    margin-left: .5rem;
                }
            }

            >span {
                margin: 0 .15rem;

                // @media (min-width: map-get($grid-breakpoints, sm)) {
                //     margin-top: 0;
                //     margin-bottom: 0;
                // }

                &:last-child {
                    margin-right: 0;
                }
            }

            .dropdown {
                line-height: 1.4;
            }

            .badge {
                font-weight: 500;
                line-height: 1.4;

                &-primary {
                    border: 1px solid $primary;
                }

                &-secondary {
                    border: 1px solid $secondary;
                }

                &-success {
                    border: 1px solid $success;
                }

                &-info {
                    border: 1px solid $info;
                }

                &-warning {
                    border: 1px solid $warning;
                }

                &-danger {
                    border: 1px solid $danger;
                }
            }
        }
    }

    .service-header-level-2 {
        margin-top: .5rem;
        padding-top: .5rem;
        border-top: 1px solid $gray-300;
    }

    .form-group {
        margin-bottom: .5rem;
    }

    .serviceline-additional {
        padding-top: .5rem;
        margin-bottom: 0;
        border-top: 1px solid $gray-300;
    }
}

.services-price {

    &-subtotal {
        color: $gray-600;
        text-align: right;
        position: relative;
        margin-top: 1rem;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -6px;
            width: 30%;
            right: 0%;
            border-top: 1px solid $gray-600;
        }
    }

    &-total {

        .service-price {
            font-weight: 700;
        }
    }
}

.service-header + .service-body {
    border-top: 1px solid $gray-200;
    padding-top: .25rem;
    margin-top: .5rem;
}

.service-body {

    &-aligned {
        padding-left: 1.8rem;
        border-top: none !important;

        &:empty {
            display: none;
        }
    }

    .service-summary {
        display: flex;
        justify-content: stretch;
        font-size: 0.75rem;
        border-top: 1px solid $gray-200;

        span {
            flex-grow: 1;
            padding: 0.25rem 0.5rem;

            &:first-child {
                flex-basis: 3em;
                flex-grow: 0;
                padding-left: 0;
            }
            
            &:last-child {
                flex-grow: 0;
                padding-right: 0;
                text-transform: none;
            }
        }
    }

    &-aligned:only-child .service-summary:first-child {
        border-top: none;
    }

    .service-address {
        margin: 0.5rem 0;
    }

    .service-usage {
        margin-top: .5rem;
        margin-bottom: .5rem;
        padding: .5rem;
        border-radius: 5px;
        background-color: $gray-300;
    }

    .services-summary-body {
        height: unset;

        .plan-wrapper {
            padding: 0;
            border-color: $gray-300;
            border: none;

            .serviceline-wrapper {
                margin-top: 0;
                padding: 0;
                background-color: transparent;

                .plan-section {
                    border-color: $gray-300;

                    h5 {
                        background-color: $gray-300;
                    }
                }
            }
        }
    }
}

.service-wrapper:nth-of-type(even) {

    .service-body {

        .service-summary {
            border-top-color: $gray-300;
        }
    }
}

.serviceline-wrapper {

    .serviceline-body {
        padding-bottom: 1rem;
    }
}

// Packages
.package-wrapper {
    border-radius: 5px;
    border: 2px solid $gray-200;
    background-color: $white;
    padding: .5rem;
    margin-bottom: .5rem;

    h6 {
        font-size: 1.2rem;
        border-bottom-width: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .package-description {
        margin-top: .5rem;
        padding-top: .5rem;
        border-top: 1px solid $gray-300;
    }

    .package-option {
        position: relative;
        padding: .25rem .5rem;
        border-radius: 5px;
        border: 1px solid $gray-300;
        transition: all .2s;

        &:last-of-type {
            margin-bottom: 0;
        }

        &-selectable {
            cursor: pointer;

            &:before {
                color: $gray-500;
                content: "\f204";
                font-size: 1rem;
                font-family: $icon-font;
                font-weight: 900;
                margin-right: .5rem;
            }
        }

        &-mandatory {
            cursor: not-allowed;
            border-color: $gray-300;
        }

        &-selected {
            border-color: lighten($primary, 25%);
            background-color: lighten($primary, 45%);

            &:before {
                color: $primary;
                content: "\f205";
            }
        }

        &-variable {
        }
    }
}

.package-option {
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .po-title {
        margin-right: auto;
    }

    .po-description {
        font-size: .9rem;
        // padding: .25rem 0;
        width: 100%;
        color: $gray-700;
    }

    .package-toolbar {
        line-height: 1.3;

        .po-quantity,
        .po-price {
            display: inline-block;
            vertical-align: middle;

            .form-number {
                margin-right: 0.5rem;

                .input-group-prepend,
                .form-control,
                .btn {
                    height: 1.5rem;
                }

                .input-group-text {
                    cursor: default;
                }

                .form-control {
                    min-width: 0;
                    padding: 0 0.25rem;
                    text-align: right;
                }

                .btn {
                    width: 1.5rem;
                    padding: 0;
                    font-size: 0.625rem;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance: textfield;
                }
            }
        }

        .po-quantity {

            .form-control {
                width: 3em;
            }
        }

        .po-price {
            display: inline-block;
            vertical-align: middle;

            .form-control {
                width: 5em;
            }
        }
    }
}

// Summary
.services-summary {
    // position: relative;
    margin-top: 1rem;
    font-family: $main-font-condensed;
    transition: all .6s ease-out .6;

    @media (min-width: map-get($grid-breakpoints, lg)) {
        margin-top: 0;
        // position: absolute;
        // // Experimental flexbox controlled height
        // display: flex;
        // flex-direction: column;
        // height: 100%;
        // top: 0;
        // left: 0;
        // right: 0;
        // width: 100%;
        // padding-left: inherit;
        // padding-right: inherit;
        // Experimental flexbox controlled height Ends
    }

    display: flex;
    flex-direction: column;
    max-height: 100%;
    flex-grow: 1;

    &-header {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        position: sticky;
        top: 0;
        z-index: 10;
        border-radius: 5px;
        padding: .5rem;
        background-color: $indigo;
        color: $white;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-weight: 500;
        font-size: 1.1rem;
        margin-bottom: .5rem;
    }

    &-applyon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        background: white;
        border: 1px solid $gray-200;
        border-radius: 0.5rem;

        h4 {
            margin-bottom: 0 !important;
        }

        .d-flex {

            @media (max-width: map-get($grid-breakpoints, sm) - 1) {
                flex-direction: column-reverse;

                .dropdown {

                    &:not(:only-child) {
                        margin-bottom: 0.5rem;
                    }

                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-body {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 100%;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            /*height: 0px;*/
        }
    }

    &-footer {
        border-top: 3px solid $gray-800;
        margin-top: auto;
        padding-top: .5rem;
        text-align: right;

        p {
            font-size: 1.3rem;
        }
    }

    h6 {
        color: $gray-600;
        margin-bottom: 0;
    }

    p {
        margin-bottom: .5rem;
    }

    .plan-wrapper {
        margin-bottom: .5rem;

        &:last-child {
            margin-bottom: 0;
        }

        >.services-price {
            &-subtotal {
                padding-right: .25rem;
                font-size: 1.1rem;

                .service-price {
                    font-weight: 700;
                }
            }
        }
    }

    .serviceline-wrapper {
        margin-top: 1rem;
        padding: .5rem .25rem 0;
        background-color: $gray-100;

        >h5 {
            padding-bottom: .5rem;
            margin-bottom: .5rem;
            border-bottom: 2px solid $indigo;
            color: $gray-700;
        }
    }

    .plan-section {
        padding: 0;
        margin-bottom: .5rem;
        background: white;
        border: 1px solid $gray-200;
        border-radius: 5px;

        &:last-child {
            margin-bottom: 0;
        }

        h5 {
            display: flex;
            justify-content: space-between;
            align-self: center;
            padding: 0.25rem;
            font-size: 1rem;
            line-height: 1.25rem;
            color: $gray-600;

            .btn {
                padding-top: 0;
                padding-bottom: 0;
                font-size: 0.75rem;
            }
        }

        >.services-price {

            &-subtotal {

                .service-price {
                    font-weight: 700;
                }
            }
        }
    }

    .package-wrapper {
        border-radius: 0;
        border-width: 0;
        background-color: transparent;
        padding: 0rem;
        margin-bottom: 0;
    }

    .package-option {
        padding: .25rem;
        margin-bottom: 0;
        background-color: transparent;
        border-width: 0;
        border-bottom: 1px solid $gray-200;
        transition: all 1s ease-out 1s;

        &:last-child {
            border-bottom: none;
        }

        &:before {
            display: none;
        }

        .po-price {
            margin-left: auto;
        }

        .po-date {
            width: 100%;
            font-size: .75rem;
            color: $gray-600;
        }
    }

    // Highlight newly added elements
    .just-added {
        -webkit-animation: indigo-fade 2s ease-in 1;
        animation: indigo-fade 2s ease-in 1;
    }

}


// The products summary list-style-position:
.list-services-summary {
    list-style-type: none;

    >li {
        font-size: 1.4rem;
    }

    ul {
        list-style-type: none;
    }

    li {
        font-size: 1rem;
    }
}