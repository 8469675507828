.cmv-progress-bar {
	margin-top: .25rem;
	margin-bottom: .25rem;
	@media (min-width: map-get($grid-breakpoints, lg)) {
	    margin-top: 0;
	    margin-bottom: 0;
	}
	.progress-header {
	    display: flex;
	    justify-content: space-between;
	    font-size: .9rem;
	    text-transform: uppercase;
	    margin-top: .25rem;
	    margin-bottom: .25rem;

	    i {
	        margin-right: .25rem;
	    }

	    .ph-left {
	    	color: $gray-700;
	    }

	    .ph-right {
	    	font-weight: 500;
	    	color: $gray-800;
	    }
	}

	.progress {
	    height: 5px;
	    margin-bottom: .25rem;
	    background-color: $gray-300;
	}

	.progress-footer {
	    font-size: 85%;
	    color: $gray-600;
	}

	// Sizes

	&-md {
		.progress {
			height: 10px;
		}
	}
}