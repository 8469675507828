// Camvio Cloud Workflow
.workflow {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-radius: .5rem;
    transition: all .2s;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        border-color: $gray-600;
    }

    .btn-circle {
        background-color: transparent;
        border-color: #adb5bd;
        color: #adb5bd;

        &.active {
            background-color: $cyan;
            color: $gray-100;
            text-decoration: none;
        }
    }

    &-header {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        > .row {

            @media (min-width: map-get($grid-breakpoints, md)) {
                flex-wrap: nowrap;
                justify-content: stretch;
            }

            > .col {

                &:nth-child(2) {
                    flex-shrink: 1;
                    flex-grow: 1;
                }

                &:last-child {
                    flex-shrink: 0;
                }
            }
        }
    }

    &-type {
        margin-bottom: 0.25rem;
        font-weight: 700;
        font-size: 0.75rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        opacity: .5;
    }

    &-version {
        font-size: 0.75rem;
        color: $gray-600;

        @media (max-width: map-get($grid-breakpoints, sm) - 1) {
            padding-bottom: .5rem;
            margin-bottom: .5rem;
            border-bottom: 1px solid $gray-300;
        }
    }

    &-caption {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: map-get($grid-breakpoints, md)) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            height: 100%;
        }
    }

    &-controls {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: map-get($grid-breakpoints, md) - 1) {
            padding-top: .5rem;
            margin-top: .5rem;
            border-top: 1px solid $gray-300;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            height: 100%;
        }

        .btn:not(:last-child) {
            margin: 0 0.25rem 0 0;
        }

        .btn-circle {

            &:disabled {
                color: $gray-500 !important;
                background: transparent !important;
            }
        }
    }

    &-details {
        flex-grow: 1;

        @media (min-width: map-get($grid-breakpoints, md)) {
            align-self: stretch;
            margin: 0 1rem 0 0;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin-right: 2rem;
        }

        h6 {
            margin-bottom: 0.5rem;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-800;

            @media (min-width: map-get($grid-breakpoints, md)) {
                font-size: 1.25rem;
            }

            i {
                margin-right: 0.25rem;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 0.875rem;
            line-height: 1.2;
            color: $gray-600;
            word-break: break-all;

            &:last-child {
                margin-bottom: 0;
            }

            &:empty {
                display: none;
            }

            span {
                display: inline-block;
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &-info {
        margin-bottom: 0.25rem;
        font-size: 0.75rem;

        &:empty {
            display: none;
        }

        > span {
            margin-right: 1rem;
            text-transform: uppercase;

            //&::after {
            //    content: '|';
            //    margin: 0 0.25rem;
            //    color: $gray-600;
            //}

            &:last-child {
                margin-right: 0;

                //&::after {
                //    display: none;
                //}
            }

            span {
                color: $gray-600;
            }
        }
    }

    &-image {
        text-align: center;
        cursor: zoom-in;

        img {
            max-width: 100%;
            margin: 0 auto;
        }
    }

    &-statuses {
        padding: 0 0.5rem;
        background-color: $gray-200;
        border: 1px solid $gray-400;
        border-radius: 0.25rem;

        .row {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: flex-start;
            margin: 0 -0.25rem;

            @media (min-width: map-get($grid-breakpoints, md)) {
                flex-direction: row;
            }
        }

        .col {
            flex-grow: 1;
            padding: 0.5rem 0.25rem;
        }

        h6 {
            margin-left: 0.375rem;
            font-weight: 700;
            font-size: 0.75rem;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $gray-600;

            @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
                font-size: 0.625rem;
            }
        }

        span {
            display: block;
            padding: 0.25rem;
            margin-bottom: 0.25rem;
            font-size: 0.625rem;
            line-height: 1.2;
            background: $gray-100;
            border: 1px solid $gray-400;
            border-radius: 0.25rem;
            user-select: none;
            cursor: default;

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: map-get($grid-breakpoints, xl)) {
                font-size: 0.75rem;
            }
        }
    }
}