// Camvio Cloud Billing Job
.billing-job {
	padding: 0.5rem;
	margin-bottom: 0.5rem;
	background-color: $gray-100;
	border: 1px solid $gray-400;
	border-radius: .5rem;
	transition: all .2s;

	&:hover {
		border-color: $gray-600;
	}

	.btn-circle {
		background-color: transparent;
		border-color: #adb5bd;
		color: #adb5bd;

		&.active {
			background-color: $cyan;
			color: $gray-100;
			text-decoration: none;
		}
	}

	&-header {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}

		> .row > [class*="col"]:last-child {
			display: flex;
			align-items: center;
		}
	}

	&-id {
		margin-bottom: 0.25rem;
		font-weight: 700;
		font-size: 0.75rem;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		opacity: .5;
	}

	&-date {
		font-size: 0.75rem;
		color: $gray-600;

		span {
			margin-left: 0.25rem;
			font-weight: 700;
		}

		@media (max-width: map-get($grid-breakpoints, md) - 1) {
			padding-bottom: .5rem;
			margin-bottom: .5rem;
			border-bottom: 1px solid $gray-300;
		}
	}

	&-caption {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		width: 100%;

		@media (min-width: map-get($grid-breakpoints, sm)) {
			flex-direction: row;
			justify-content: space-between;
		}
		@media (min-width: map-get($grid-breakpoints, md)) {
			height: 100%;
			justify-content: flex-end;
		}
	}

	&-progress {

		@media (max-width: map-get($grid-breakpoints, md) - 1) {
			margin-bottom: 0.5rem;
		}

		&-status {
			width: 100%;
			padding: 0.25rem 0.5rem;
			margin-bottom: 0.25rem;
			text-align: center;
			text-transform: capitalize;
			color: white;
			border-radius: .75rem;
			border: 4px solid $gray-300;
			background-color: rgba($white, .3);

			@media (min-width: map-get($grid-breakpoints, md)) {
				width: auto;
				min-width: 12em;
			}

			&.bg-light,
			&.bg-light-with-pulse {
				color: inherit;
			}
		}

		&-stage {
			display: flex;
			justify-content: space-between;
			padding: 0 0.25rem;
			margin: 0;
			font-size: 0.875rem;
			line-height: 1;
			color: $gray-600;
		}
	}

	&-details {
		text-align: left;

		@media (min-width: map-get($grid-breakpoints, sm)) {
			text-align: right;
		}

		@media (min-width: map-get($grid-breakpoints, md)) {
			margin-left: auto;
			margin-right: 1rem;
		}

		&-accounts,
		&-amount {
			margin: 0;
		}

		&-accounts {
			font-size: 1rem;
		}

		&-amount {
			font-size: 0.875rem;
		}
	}

	&-controls {
		align-self: stretch;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		@media (max-width: map-get($grid-breakpoints, md) - 1) {
			width: 100%;
			flex-basis: 100%;
			flex-shrink: 0;
			padding-top: .5rem;
			margin-top: .5rem;
			border-top: 1px solid $gray-300;
		}

		.btn:not(:last-child) {
			margin: 0 0.25rem 0 0;
		}

		.btn-circle {

			&:disabled {
				color: $gray-500 !important;
				background: transparent !important;
			}
		}
	}

	&-stages {

		.row {
			display: flex;
			flex-direction: column;
			justify-content: stretch;
			align-items: stretch;

			@media (min-width: map-get($grid-breakpoints, md)) {
				flex-direction: row;
			}
		}

		.col {
			position: relative;
			display: flex;
			flex-grow: 1;

			@media (max-width: map-get($grid-breakpoints, md) - 1) {
				margin-bottom: 2rem;
			}

			&::after {
				content: "»";
				position: absolute;
				z-index: 1;
				width: 2rem;
				height: 2rem;
				font-size: 1.25rem;
				line-height: 2rem;
				text-align: center;

				@media (max-width: map-get($grid-breakpoints, md) - 1) {
					top: 100%;
					left: 50%;
					transform: rotateZ(90deg);
					margin: 0 0 0 -1rem;
				}
				@media (min-width: map-get($grid-breakpoints, md)) {
					top: 50%;
					left: 100%;
					margin: -1rem 0 0 -1rem;
				}
			}

			&:last-child::after {
				display: none;
			}
		}
	}

	&-stage,
	&-parameters-content {
		width: 100%;
		padding: 0.5rem;
		background-color: $gray-200;
		border: 1px solid $gray-400;
		border-radius: 0.25rem;

		h6 {
			font-size: 0.75rem;
			letter-spacing: 0.1em;
			text-align: center;
			text-transform: uppercase;
			color: $gray-600;
		}

		p {
			display: flex;
			justify-content: space-between;
			margin-bottom: 0.25rem;
			font-size: 0.75rem;
			line-height: 1.2;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-note {
			margin-top: 1rem;
		}
	}

	&-parameters {
		display: flex;
		flex-direction: column;

		&-toggle {
			align-self: flex-end;
			padding: 0;
			margin: 0.5rem 0;
			font-size: 0.75rem;
			text-align: right;
			background: transparent;
			border: none;
			outline: none !important;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-content {

			.row {
				display: flex;
				flex-direction: column;
				justify-content: stretch;

				@media (min-width: map-get($grid-breakpoints, md)) {
					flex-direction: row;
				}
			}

			.col {

				@media (max-width: map-get($grid-breakpoints, md) - 1) {
					margin-bottom: 0.25rem;

					&:empty {
						display: none;
					}
				}
			}
		}
	}
}