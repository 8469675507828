// All Colors Badges
@each $name,
$color in $colors {
    .badge-#{$name} {
        color: $white;
        background-color: $color;
    }
}

// All Colors Badges Ends

// Outlined badges
@each $name,
$color in $theme-colors {
    .badge-outline-#{$name} {
        border: 1px solid $color;
        color: $color;
        background-color: transparent;
    }
}

// Outlined badges Ends
.badge {

    &-table {
        padding: 2px .5rem;
        border-radius: .25rem;
        font-size: .85rem;
        font-weight: 600;
        display: inline-block;
    }

    &-line-type {
        font-weight: 400;
        font-size: 85%;
        color: $indigo;
        padding: .25rem .5rem;
        border: 2px solid $indigo;
    }

    &-tag {

        button {
            margin-left: .5rem;
            color: inherit;
            border: 0;
            background: transparent;
        }
    }

    &-verified {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 10rem;
        margin-right: 0.25rem;
        background-color: $success;
        color: $white;
        position: relative;
        top: 3px;

        &:after {
            position: absolute;
            left: 4px;
            font-size: 0.75rem;
            content: "\2713 ";
        }
    }

    &-notification {
        opacity: .8;
        display: inline-block;
        position: relative;
        transform: translate(240%, -80%);
        font-size: .625rem;
        border-radius: 3rem;
        padding: .125rem .375rem;
        line-height: 1;
    }
}