.cmv-footer {
	height: 1.5rem;
	color: $gray-400;
	background-color: rgba($gray-800, 0.8);

	.page-wrapper-sidebar &,
	.page-wrapper-nosidebar & {
		color: $gray-600;
		background: $white-darker;
	}

	.cmv-copyrights {
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 0.75rem;
		line-height: 1.5rem;
		overflow: hidden;
	}
}