// Camvio Cloud background utilities
.bg {
    &-gray-100 {
        color: $gray-700;
        background-color: $gray-100;
    }

    &-gray-200 {
        color: $gray-700;
        background-color: $gray-200;
    }

    &-gray-300 {
        color: $gray-800;
        background-color: $gray-300;
    }
}

// Pulsating Backgrounds
@each $name,
$color in $theme-colors {
    .bg-#{$name}-with-pulse {
        animation-name: pulse-#{$name};
        animation-duration: 2s;
        animation-iteration-count: infinite;
        text-shadow: 0px -1px 1px $color,
            0px 1px 1px $color,
            1px 0px 1px $color,
            -1px 0px 1px $color;

        &:after {
            background-color: $color;
            animation-name: pulse-#{$name};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
    }
}
// Pulsating Backgrounds Ends

// Lighter Backgrounds
@each $name,
$color in $theme-colors {
    .bg-#{$name}-light {
        background-color: lighten($color, 40%);
    }
}
// Lighter Backgrounds Ends

// Lighter Borders
@each $color, $value in $theme-colors {
    .border-#{$color}-light {
        border-color: lighten($value, 20%) !important;
    }
}
// Lighter Borders Ends