.available-services {
    padding: 1rem;
    background: white;
    border: 1px solid $gray-300;
    border-radius: 0.5rem;

    @media (max-width: map-get($grid-breakpoints, xl) - 1) {
        margin: 0 -1rem;
    }

    &-address {
        margin-bottom: 1rem !important;
    }

    &-select {
        margin-bottom: 1rem;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            display: flex;
            align-items: center;
        }

        label {
            margin: 0 0 0.25rem 0;

            @media (min-width: map-get($grid-breakpoints, sm)) {
                margin: 0 0.5rem 0 0;
            }

            + div {
                flex-grow: 1;
            }
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
}