// Camvio Cloud Appointment List
.appointment-list {

    .btn-circle {
        background-color: transparent;
        border-color: #adb5bd;
        color: #adb5bd;

        &.active {
            background-color: $cyan;
            color: $gray-100;
            text-decoration: none;
        }

        &:disabled {
            color: $gray-500 !important;
            background: transparent !important;
        }
    }

    .appointment {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: $gray-100;
        border: 1px solid $gray-400;
        border-radius: .5rem;
        transition: all .2s;

        &:last-child {
            margin-bottom: 0;

            &:not(:first-child) {

                .dropdown-menu {
                    top: auto;
                    bottom: 100%;
                }
            }
        }

        &:hover {
            border-color: $gray-600;
        }

        .row {

            @media (min-width: map-get($grid-breakpoints, md)) {
                flex-wrap: nowrap;
                justify-content: stretch;
            }

            .col {

                &:nth-child(2) {
                    flex-shrink: 1;
                    flex-grow: 1;
                }
                &:nth-child(3) {
                    flex-shrink: 0;
                }
            }
        }

        &-source {
            margin-bottom: 0.25rem;
            font-weight: 700;
            font-size: 0.75rem;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            opacity: .5;
        }

        &-info,
        &-details {
            font-size: 0.75rem;
            color: $gray-600;

            > span {

                @media (max-width: map-get($grid-breakpoints, md) - 1) {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 0.5em;
                    max-width: 100%;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @media (min-width: map-get($grid-breakpoints, md)) {
                    display: block;
                }

                i {
                    margin-right: 0.25em;
                }
            }
        }

        &-info {

            @media (max-width: map-get($grid-breakpoints, md) - 1) {
                padding-bottom: .5rem;
                margin-bottom: .5rem;
                border-bottom: 1px solid $gray-300;
            }

            > span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;

            &-assignees {

                span {

                    &:empty {
                        display: none;
                    }

                    &:not(:last-child) {

                        &::after {
                            content: ", ";
                        }
                    }
                }
            }
        }

        &-title {
            line-height: 1.25;

            &-address,
            &-date {
                display: inline;
                margin-right: 0.25rem;
                font-size: 0.875rem;
                color: $gray-600;

                @media (min-width: map-get($grid-breakpoints, xl)) {
                    margin-bottom: 0 !important;
                }
            }

            &-date {
                white-space: nowrap;
            }

            &-name,
            &-service {
                line-height: 1.5;
            }

            &-name {
                display: block;
                margin-top: 0.25rem;
                color: $gray-600;

                button {
                    padding: 0;
                    margin: 0;
                    text-decoration: underline;
                    color: inherit !important;
                    background: transparent;
                    border: none;
                    outline: none;
                    cursor: pointer;

                    &:disabled {
                        cursor: default;
                        opacity: .8;
                    }

                    &:hover,
                    &:disabled {
                        text-decoration: none;
                    }
                }
            }

            &-service {
                margin: 0.25rem 0;
                font-weight: 500;
                line-height: 1.2;
                color: $gray-700;

                @media (min-width: map-get($grid-breakpoints, md)) {
                    margin-top: 0.25rem;
                    margin-bottom: 0;
                    font-size: 1.125rem;
                }
            }

            i {
                width: 1.25rem;
                text-align: center;
                font-size: 0.875rem;
            }
        }

        &-controls {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: map-get($grid-breakpoints, md) - 1) {
                position: relative;
                width: 100%;
                padding-top: .5rem;
                margin-top: .5rem;
                border-top: 1px solid #dee2e6;

                .dropdown {
                    position: static;
                }

                .dropdown-menu {
                    left: 0;
                    right: 0;
                }
            }

            @media (min-width: map-get($grid-breakpoints, md)) {
                justify-content: flex-end;
                height: 100%;

                .dropdown-menu {
                    margin: 0.5rem 0;
                }
            }
        }
    }
}