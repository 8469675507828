.cmv-alert {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &-system {
        margin-top: 0;
        margin-bottom: 0;

        .cmv-alert-content {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            max-width: $cmv-bs-container-max-width;
            padding: 1rem $grid-gutter-width / 2;
            flex-wrap: wrap;

            .cmv-alert-messages {
                padding-left: 1rem;
                display: flex;
                align-items: center;

                &:before {
                    font-family: $icon-font;
                    font-weight: 900;
                    font-size: 1.2rem;
                    left: -12px;
                    position: relative;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .cmv-alert-actions {
                margin-left: auto;
            }
        }

        &-success {
            background-color: $success;

            .cmv-alert-messages {
                color: color-yiq($success);

                &:before {
                    content: "\f058";
                }
            }
        }

        &-info {
            background-color: $info;

            .cmv-alert-messages {
                color: color-yiq($info);

                &:before {
                    content: "\f05a";
                }
            }
        }

        &-warning {
            background-color: $warning;

            .cmv-alert-messages {
                color: color-yiq($warning);

                &:before {
                    content: "\f071";
                }
            }
        }

        &-danger {
            background-color: $danger;

            .cmv-alert-messages {
                color: color-yiq($danger);

                &:before {
                    content: "\f071";
                }
            }
        }
    }
}