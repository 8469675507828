.ticket {

    &-wrapper {
        background-color: $gray-100;
        border: 1px solid $gray-400;
        border-radius: .5rem;
        padding: 0.5rem;
        margin: 0.5rem 0 0;
        transition: all .2s;

        &:hover {
            border-color: $gray-600;
        }

        .btn-circle {
            background-color: transparent;
            border-color: #adb5bd;
            color: #adb5bd;

            &.active {
                background-color: $cyan;
                color: $gray-100;
                text-decoration: none;
            }
        }
    }

    &-header,
    &-status,
    &-notes,
    &-lines {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-header {

        > .row > [class*="col"] {

            @media (min-width: 1600px) {

                &:nth-child(1) {flex-basis: 20%; max-width: 20%;}
                &:nth-child(2) {flex-basis: 48%; max-width: 48%;}
                &:nth-child(3) {flex-basis: 20%; max-width: 20%;}
                &:nth-child(4) {flex-basis: 12%; max-width: 12%;}
            }
        }
    }

    &-source {
        margin-bottom: 0.25rem;
        font-weight: 700;
        font-size: 0.75rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        opacity: .5;
    }

    &-date {
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        font-size: 0.75rem;
        border-bottom: 1px solid $gray-300;

        @media (min-width: map-get($grid-breakpoints, sm)) {

            &:last-child {
                border-bottom: none;
            }
        }

        @media (min-width: map-get($grid-breakpoints, md)) {

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    &-caption {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;

        @media (min-width: map-get($grid-breakpoints, md)) {
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }
    }

    &-details {

        @media (max-width: map-get($grid-breakpoints, md) - 1) {
            flex-basis: 100%;
            flex-shrink: 0;
            margin: 0 0 1rem 0;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            align-self: stretch;
            margin: 0 1rem 0 0;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin-right: 2rem;
        }

        h6 {
            margin-bottom: 0.25rem;
            font-weight: 500;
            font-size: 1rem;
            color: $gray-700;

            @media (min-width: map-get($grid-breakpoints, md)) {
                font-size: 1.125rem;
            }

            i {
                margin-right: 0.25rem;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 0.875rem;
            color: $gray-600;
            line-height: 1.5;

            &:last-child {
                margin-bottom: 0;
            }

            &:empty {
                display: none;
            }

            span {
                display: inline-block;
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-name {

            span {

                @media (min-width: 1600px) {
                    display: block !important;
                }
            }

            button {
                padding: 0;
                margin: 0;
                text-decoration: underline;
                color: inherit !important;
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &-progress {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media (min-width: 1600px) {
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
        }

        .progress {
            min-width: 100%;
            height: 2rem;
            border-radius: .75rem;
            border: 4px solid $gray-300;
            background-color: rgba($white, .3);
            margin-bottom: 0;

            @media (min-width: 1600px) {
                min-width: 0;
                width: calc(100% - 8em);
                margin-top: auto;
            }

            .progress-bar {
                width: 100%;
                background-color: $white;
            }
        }

        > .btn {
            width: calc(100% - 0.5rem);
            height: 2rem;
            line-height: 2rem;
            padding: 0;
            margin: 0.5rem 0.25rem 0;
            font-size: .75rem;
            white-space: nowrap;

            @media (min-width: 1600px) {
                width: 5rem;
                margin-top: auto;
                margin-bottom: 0;
            }
        }

        .order-toolbar {
            width: 100%;
            justify-content: center;
            height: auto;
            margin-top: 0.5rem;
            margin-bottom: 0.25rem;

            @media (min-width: 1600px) {
                justify-content: flex-end;
            }
        }
    }

    &-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            height: 100%;
        }

        .btn:not(:last-child) {
            margin: 0 0.25rem 0 0;

            @media (min-width: map-get($grid-breakpoints, md)) and (max-width: 1599px) {
                margin: 0 0 0.25rem 0;
            }
        }

        .btn-circle {

            &:disabled {
                color: $gray-500 !important;
                background: transparent !important;
            }
        }
    }

    &-modified {
        display: block;
        width: 100%;
        padding: 0 0.25rem;
        margin-top: 0.25rem;
        font-size: 0.625rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
            text-align: center;
        }
        @media (min-width: 1600px) {
            margin-bottom: auto;
            text-align: right;
        }

        span {
            display: inline-block;
            margin-left: 0.5rem;
        }

        i {
            margin-right: 0.25rem;
        }
    }

    &-status {
        margin: 1rem 0;
    }

    &-lines,
    &-notes {

        .btn {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-notes {
        width: 100%;
        margin-top: 0.5rem;

        .mini-notes {
            background: $gray-200;
            border: 1px solid $gray-400;
            border-radius: .25rem;

            &-h-constrained {
                overflow-y: auto;
            }

            .mini-note {

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .form-control {
            border-color: $gray-400;
        }
    }

    &-lines {

        .row {
            align-items: flex-start;
        }

        + div {
            margin-bottom: -0.5rem;
        }
    }

    &-wrapper {

        .plan-wrapper {
            background: transparent;
        }

        .service-wrapper {
            margin: 0 -0.5rem;
            padding: 0.5rem;

            &:nth-of-type(even) {
                background: $gray-100;
            }
            &:nth-of-type(odd) {
                background: $gray-200;
            }

            &:last-child {
                border-radius: 0 0 .5rem .5rem;
            }

            .service-body {
                padding-top: 0;
                margin-bottom: 0.5rem;
                border-top: none;
            }
        }

        .cmv-container-user-tasks {
            padding: 0;
            margin: 0 -0.5rem;
            margin-bottom: -0.5rem;

            &:empty {
                display: none;
            }

            .cmv-container-table,
            .cmv-table {
                padding-bottom: 0;
                margin-bottom: 0;

                tr:last-child td {

                    &:first-child {border-radius: 0 0 0 0.5rem;}
                    &:last-child {border-radius: 0 0 0.5rem 0;}
                }
            }
        }
    }
}