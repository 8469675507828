.modal {
    overflow: hidden !important;

    iframe {
        display: block;
        height: auto;
    }

    form {
        height: 100%;
    }

    .modal-dialog {
        height: 100%;
        padding: 0.5rem;
        margin: 0 auto;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            padding: 1.75rem 0;
        }

        &-wide {

            @media (min-width: map-get($grid-breakpoints, lg)) {
                max-width: map-get($grid-breakpoints, md);
            }
        }
    }

    .modal-content {
        max-height: 100%;
        padding-bottom: 4.25rem;
        min-width: fit-content;
        border: none;
    }

    .modal-header {
        flex-shrink: 0;
        min-height: 2.625rem;
        background-color: $primary;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border-bottom-width: 0;

        .modal-title {
            font-size: 1.125rem;
            color: color-yiq($primary);
            text-transform: uppercase;
        }

        .close {
            opacity: 1;
            font-size: 2rem;
            margin-top: -1.25rem;
            margin-bottom: -1.25rem;
            color: color-yiq($primary);
            text-shadow: none;
            outline: none;

            &[disabled] {
                opacity: 0.5;
            }
        }
    }

    .modal-body {
        overflow-y: auto;
        scroll-behavior: smooth;

        &-full {
            padding: 0;
        }

        div[class*="css-"][class*="-container"] {
            overflow: hidden;

            div[class$="-menu"] {
                position: static;

                > div {
                    max-height: 119px;

                    @media (min-width: map-get($grid-breakpoints, sm)) {
                        max-height: 156px;
                    }
                    @media (min-width: map-get($grid-breakpoints, md)) {
                        max-height: 193px;
                    }
                    @media (min-width: map-get($grid-breakpoints, lg)) {
                        max-height: 230px;
                    }
                    @media (min-width: map-get($grid-breakpoints, xl)) {
                        max-height: 267px;
                    }
                }
            }
        }
    }

    .modal-footer {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 4.25rem;
        background: white;
    }
}