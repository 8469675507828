.account360 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    @media (min-width: map-get($grid-breakpoints, lg)) {
        height: 100%;
        font-size: .875rem;
        overflow-y: auto;
    }

    &-tile {
        flex-basis: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        padding: 1.5rem;
        line-height: 1.2;
        border-bottom: 1px solid $gray-200;
        overflow: hidden;

        &-clickable {
            cursor: pointer;
        }

        &:nth-child(odd) {
            background: $gray-100;
        }

        @media (max-width: map-get($grid-breakpoints, md) - 1) {

            &:last-child {
                border-bottom: none;
            }
        }

        @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {
            flex-basis: 50%;
            border-right: 1px solid $gray-200;

            &:nth-child(2n):nth-last-child(2) ~ &,
            &:nth-child(2n):nth-last-child(3) ~ & {
                border-bottom: none;
            }

            &:nth-child(2n) {
                border-right: none;
            }
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
            flex-basis: 33.33%;
            border-right: 1px solid $gray-200;

            &:nth-child(3n):nth-last-child(2) ~ &,
            &:nth-child(3n):nth-last-child(3) ~ &,
            &:nth-child(3n):nth-last-child(4) ~ & {
                border-bottom: none;
            }

            &:nth-child(3n) {
                border-right: none;
            }
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            min-height: 33.33%;
        }

        &-title {
            margin-bottom: 1rem;
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 0.1em;
            color: $gray-600;

            &:empty {
                display: none;
            }

            i {
                margin-right: 0.25rem;
            }
        }

        &-content {

            .line-top {
                margin-bottom: 0.5rem;

                &:not(:first-child) {
                    padding-top: 0.5rem;
                    border: none;
                    border-top: 1px solid $gray-300;
                }
            }

            h6 {
                font-size: 1em;

                .text-muted {
                    margin-right: 0.125rem;
                    font-weight: 400;
                }
            }

            p {
                margin-bottom: 0.5rem;
            }
        }

        *:last-child:not(hr) {
            margin-bottom: 0;
        }
    }

    .card-plan {
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;

        .card-body {
            padding: 0;
        }

        .service-wrapper {
            background: transparent;
        }

        .service-body-aligned {
            padding: 0;
        }
    }
}