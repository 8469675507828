// Mini Notes Component
.mini-notes {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-radius: 5px;
        margin-bottom: .5rem;
        padding: .25rem;
        border-bottom: 1px dotted $gray-500;
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: .25rem;
            padding-bottom: .5rem;
            border-bottom: 1px dotted $gray-400;
            margin-bottom: .5rem;
            .mini-note-title {
                color: $gray-600;
                font-size: .85rem;
            }
            .mini-note-toolbar {
                margin-left: auto;
            }
        }
        .footer {
            border-top: 1px dotted $gray-400;
            margin-top: .25rem;
            padding-top: .25rem;
        }
        &:nth-of-type(odd) {
            background-color: rgba($white, .3);
        }
        &:nth-of-type(even) {
            background-color: rgba($white, .3);
        }
        &:hover {
            background-color: rgba($white, .7);
        }

        .mini-note-body {
            position: relative;
            text-align: right;

            p {
                padding: 0 0 0 1.25rem;
                margin: 0;
                font-size: .9rem;
                color: $gray-600;
                overflow-wrap: break-word;
            }

            .mini-note-delete {
                position: absolute;
                top: 0;
                left: 0;
                color: $gray-600;
                text-align: center;
                border-radius: 100px;

                &-button {
                    pointer-events: all;
                    cursor: pointer;

                    &:hover {
                        color: white;
                        background: $gray-600;
                    }
                }
            }
        }

        &.mini-note-current-user {
            background-color: rgba($white, .6);
            position: relative;

            .mini-note-body {
                text-align: left;

                p {
                    padding: 0 1.25rem 0 0;
                }

                .mini-note-delete {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}

// Mini Notes With Set height
.mini-notes-h-constrained {
    max-height: 200px;
    overflow-y: scroll;
    // border: 1px solid $input-border-color;
    padding: .5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
}