$pie-color: $gray-300;
$bg-gray: $gray-400;

$dark-yellow: #f8DA00;

$pie-size: 50px;
$donut-size: 16px;
$text-size: 1.2rem;

@mixin translateX($x) {
  -webkit-transform: translateX($x);
      -ms-transform: translateX($x); // IE9 only
       -o-transform: translateX($x);
          transform: translateX($x);
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
     -moz-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9 only
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin position-center() {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

@mixin horizontal-gradient( $start-color, $end-color) {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/ */
	background-image: $start-color; /* Old browsers */
	background-image: -moz-linear-gradient(left, $start-color 0%, $start-color 50%, $end-color 50%, $end-color 100%); /* FF3.6-15 */
	background-image: -webkit-linear-gradient(left, $start-color 0%,$start-color 50%,$end-color 50%,$end-color 100%); /* Chrome10-25,Safari5.1-6 */
	background-image: linear-gradient(to right, $start-color 0%,$start-color 50%,$end-color 50%,$end-color 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 ); /* IE6-9 */
}

.percentage-circle {
	@include horizontal-gradient($bg-gray, $pie-color);
	position: relative;
	width: $pie-size;
	height: $pie-size;
	background-color: $bg-gray;
	border-radius: 50%;
	margin: 0 auto;
	&:before, &:after{
		content: "";
		display: block;
	}
	&:before{
		margin-left: 50%;
		height: 100%;
		transform-origin: left;
		border-radius: 0 100% 100% 0 / 50%;
	}
	&:after{
		@include position-center();
		border-radius: 50%;
		background: $gray-200;
		width: $pie-size - $donut-size;
		height: $pie-size - $donut-size;
		z-index: 10;
	}
	span{
		@include position-center();
		@include translateX(2px);
		display: block;
		width: 100%;
		height: $text-size;
		font-size: $text-size;
		line-height: 1;
		// letter-spacing: 1px;
		text-align: center;
		z-index: 50;
		// font-weight: 700;
	}
}

.percentage-circle{
	&.circle-red{
		$pie-color: $danger;
		@include horizontal-gradient($bg-gray, $pie-color);
		span{ color: darken($pie-color, 5%); }
	}
	&.circle-orange{
		$pie-color: $orange;
		@include horizontal-gradient($bg-gray, $pie-color);
		span{ color: darken($pie-color, 5%); }
	}
	&.circle-yellow{
		$pie-color: $warning;
		@include horizontal-gradient($bg-gray, $pie-color);
		span{ color: darken($pie-color, 5%); }
	}
	&.circle-green{
		$pie-color: $success;
		@include horizontal-gradient($bg-gray, $pie-color);
		span{ color: darken($pie-color, 5%); }
	}
}

@for $i from 1 through 50 {
	.circle-#{$i} {
		&:before{
			$percentage: $i;
			$deg: ($percentage / 100) * 360deg;
			background-color: inherit;
			@include rotate( $deg );
		}
	}
}

@for $i from 51 through 100 {
	.circle-#{$i} {
		&:before{
			$percentage: $i;
			$deg: (($percentage - 50) / 100) * 360deg;
			@include rotate( $deg );
		}
		&.circle-red{
			$pie-color: $danger;
			&:before{ background-color: $pie-color; }
		}
		&.circle-orange{
			$pie-color: $orange;
			&:before{ background-color: $pie-color; }
		}
		&.circle-yellow{
			$pie-color: $warning;
			&:before{ background-color: $pie-color; }
		}
		&.circle-green{
			$pie-color: $success;
			&:before{ background-color: $pie-color; }
		}
	}
}

.cmv-progress-circle {
	display: flex;
	flex-wrap: wrap;
	margin-top: .5rem;
	margin-bottom: .5rem;
	&-col {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;

		&-text {
			padding-left: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;

			h6 {
				display: block;
				color: $gray-600;
				font-size: 1.1rem;
				padding-bottom: .25rem;
				margin-bottom: .25rem;
			}

			p {
				margin-bottom: 0;
			}
		}
	}
}
