/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background: $primary;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
::-webkit-scrollbar-thumb:active {
  background: $primary;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}