// Camvio Cloud Appointment Calendar
.appointment-calendar {
    margin: 0 -1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    overflow: visible;

    @media (max-width: map-get($grid-breakpoints, md) - 1) {
        min-height: calc(100vh - 370px);
        padding-bottom: 140px;
        overflow-x: auto;
    }

    .btn-circle {
        background-color: transparent;
        border-color: #adb5bd;
        color: #adb5bd;

        &.active {
            background-color: $cyan;
            color: $gray-100;
            text-decoration: none;
        }
    }

    .row {
        flex-wrap: nowrap;
        margin: 0;

        .col {
            padding: 0;
        }
    }

    &-headings,
    &-row {
        font-size: 0.75rem;
        border-bottom: 1px solid $gray-300;

        @media (max-width: map-get($grid-breakpoints, md) - 1) {
            min-width: map-get($grid-breakpoints, md);
        }
        @media (min-width: 1600px) {
            font-size: 0.875rem;
        }
    }

    &-headings .col:first-child,
    &-row .col:first-child {
        flex-shrink: 0;
        flex-grow: 0;
    }

    &-heading {
        display: block;
        padding-bottom: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &-bold {
            font-weight: 700;
        }
    }

    &-headings .col:first-child &-heading,
    &-row &-heading {
        width: 7em;
    }

    &-headings &-heading {
        padding-left: 0.375rem;
    }

    &-row {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:nth-child(even) {
            background: $gray-100;
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

        .col:last-child {
            display: grid;
            grid-auto-flow: column;
            grid-template-areas: "area area area area";
            grid-template-columns: repeat(11, [column] 1fr);
            column-gap: 4px;
            row-gap: 4px;
        }
    }

    &-row {

        &:last-child {
            border-radius: 0 0 0.5rem 0.5rem;

            &:not(:nth-child(2)) {

                .dropdown-menu {
                    top: auto;
                    bottom: 100%;
                }
            }
        }
    }

    .appointment {
        grid-area: "area";
        padding: 0.25rem;
        background-color: $gray-100;
        border: 1px solid $gray-400;
        border-radius: .5rem;
        transition: all .2s;

        @media (min-width: 1600px) {
            padding: 0.5rem;
        }

        &:hover {
            border-color: $gray-600;
        }

        @for $i from 8 to 20 {
            &[data-from="#{$i}"] { grid-column-start: column #{$i - 7} }
            &[data-to="#{$i}"] { grid-column-end: column #{$i - 7} }
        }

        &-top,
        &-bottom {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            @media (min-width: map-get($grid-breakpoints, xl)) {
                flex-direction: row;
            }

            &-left {
                flex-shrink: 1;
                flex-grow: 1;

                @media (min-width: map-get($grid-breakpoints, xl)) {
                    padding-right: 0.5rem;
                }
            }

            &-right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                flex-shrink: 0;
                flex-grow: 0;
            }
        }

        &-top {

            &-right {

                @media (max-width: map-get($grid-breakpoints, xl) - 1) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                }
            }
        }

        &-bottom {

            @media (max-width: map-get($grid-breakpoints, xl) - 1) {
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                font-size: 0.625rem;
            }
        }

        &-name,
        &-id,
        &-address {
            margin-bottom: 0.5rem;
            line-height: 1.2;
        }

        &-name {
            display: block;
            color: $gray-600;

            button {
                padding: 0;
                margin: 0;
                text-decoration: underline;
                color: inherit !important;
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;

                &:disabled {
                    cursor: default;
                    opacity: .8;
                }

                &:hover,
                &:disabled {
                    text-decoration: none;
                }
            }
        }

        &-address {
            display: block;
        }

        &-controls {

            .dropdown-menu {
                margin: 0.5rem 0;
            }
        }

        &-services,
        &-area {
            display: block;
            margin-top: 1rem;
            line-height: 1.2;

            &:empty {
                display: none;
            }

            i {
                margin-right: 0.25rem;
            }
        }

        &-id {
            font-weight: 700;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}