// Misc Utilities

// Disable Scrollbars
.disable-scrollbars {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        display: none;
        background: transparent;
        /* Chrome/Safari/Webkit */
    }
}

// Disable Scrollbars Ends

// Flex columns
.col-flex {
    display: flex;
}

// Flex columns Ends

iframe {
    width: 100%;
    height: 100vh;
    border: 0px solid transparent;
    padding: 0;
}

.blur {
    // background: url('https://images.unsplash.com/photo-1486723312829-f32b4a25211b?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=&bg=') no-repeat center center fixed;
    // background-size: cover;
    // overflow: hidden;
    filter: blur(13px);
}

.checklist {
    display: flex;
    margin-top: .25rem;
    margin-bottom: .25rem;

    .checklist-item {
        width: 100%;
        align-items: center;

        .checklist-q {
            color: $gray-600;
            font-weight: 700;
        }

        .checklist-a {
            color: $gray-800;
        }
    }

    label {
        margin-bottom: 0;
    }
}

// Widths
.w-80px {
    max-width: 80px;
}

.w-100px {
    max-width: 100px;
}

.w-120px {
    max-width: 120px;
}

.w-100pct {
    width: 100%;
}

.h-300 {
    max-height: 300px;
    overflow-y: auto;
}

// Positionings
.pos {
    &-abs {
        position: absolute;
    }

    &-rel {
        position: relative;
    }
}

// Links
a {
    cursor: pointer;
}

.link-map {
    // color: $danger;
    padding-left: .875rem;

    &:hover {
        text-decoration: none;
    }

    &:before {
        font-family: $icon-font;
        font-weight: 900;
        font-size: .875rem;
        content: '\f5a0';
        position: relative;
        left: -10px;
    }

    &:after {
        color: rgba($black, .4);
        font-family: $icon-font;
        font-weight: 900;
        font-size: .75rem;
        content: '\f360';
        position: relative;
        top: -4px;
        left: 3px;
    }
    }
// Links Ends

.nowrap {
    white-space: nowrap;
}

.col-full-width {
    width: 100%;
    max-width: 100%;
    flex-basis: auto;
    animation: slideInFromRight 0.1s;

    .services-summary-body {
        height: 100%;
    }
}

.col-width-animated {
    transform: translate(-100%, 0);
    // transition: all .2s ease;
}

// Icons
.icon {
    &-service-provider {
        width: 60px;
        // height: 16px;
        margin-left: .25rem;
        margin-right: .5rem;
    }
}
// Text sizes
.text {
    &-xl {
        font-size: 1.75rem;
    }

    &-lg {
        font-size: 1.5rem;
    }

    &-md {
        font-size: 1.25rem;
    }

    &-sm {
        font-size: .875rem;
    }

    &-xs {
        font-size: .75rem;
    }
}

// Text colors
.text {
    &-gray-300 {
        color: $gray-300;
    }
    &-gray-400 {
        color: $gray-400;
    }
    &-gray-500 {
        color: $gray-500;
    }
    &-gray-600 {
        color: $gray-600;
    }
}
// Text opacities
.text {
    &-op-6 {
        opacity: .6;
    }
}