// Override WebKit's prepopulated input
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px $input-bg inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

// Custom select invalid style
div[class*="css-"][class*="-container"].is-invalid div[class*="-control"],
div[class*="css-"][class*="-container"].is-invalid span[class*="-a11yText"] ~ div[class*="-control"] {
    border-color: #bf433d !important;
}

// Add Placeholder for Date fields
input[type="date"]::before {
    color: #999999;
    content: attr(placeholder);
}

input[type="date"] {
    color: #999999;
}

input[type="date"]:focus,
input[type="date"]:focus::before,
input[type="date"]:valid::before {
    content: "" !important;
}


.as-label {
    font-size: 1rem;
}

.cmv-form {

    &-error {
        font-size: 0.875rem;
        color: $danger;
    }

    h1, h2, h3, h4, h5,
    .h1, .h2, .h3, .h4, .h5 {
        color: $gray-700;
    }

    label {
        color: $gray-800;
        margin-bottom: .25rem;

        &.small {
            font-size: 0.75rem;
            line-height: 1.2;
        }
    }

    h4 {
        font-size: 1.25rem;
        margin-bottom: .5rem;
    }

    hr {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

    fieldset {
        padding: 1rem 0.5rem 0;
        margin-bottom: 1rem;
        background: $gray-100;
        border: 1px solid $gray-500;
        border-radius: 0.25rem;

        &.is-invalid {
            border-color: #bf433d;

            & + .invalid-feedback {
                margin-top: -0.75rem;
            }
        }
        &.is-valid {
            border-color: #12a141;

            & + .valid-feedback {
                margin-top: -0.75rem;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        legend {
            display: inline-block;
            width: auto;
            padding: 0 0.5rem;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 1;
        }

        .form-group {
            padding: 0 0.5rem;
        }
    }

    .form-group {

        &.row {

            [class*="col-lg"] {

                &:first-child {

                    @media (min-width: map-get($grid-breakpoints, lg)) {
                        display: flex;
                        align-items: center;
                        height: 2.5rem;
                    }
                }
            }

            label {
                margin: 0.125rem 0;
            }
        }
    }

    &-footer {
        > .container {
            flex-wrap: wrap;

            .btn {
                margin-top:.25rem;
                margin-bottom:.25rem;
            }
        }
    }

    &-client-users {

        .form-subsection {
            padding: .5rem;
            margin-bottom: 1rem;
            background-color: $gray-100;
            border: 1px solid $gray-200;
            border-radius: 5px;

            h6,
            .form-header {
                padding-bottom: .5rem;
                margin-bottom: 1rem;
                color: $gray-600;
                border-bottom: 1px solid $gray-500;
            }

            .form-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                &:only-child,
                h6 {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }

                .btn {
                    display: block;
                }

                .h-check {

                    &, label, input + span {
                        margin: 0;
                    }

                    label {
                        display: block;
                    }

                    span {
                        line-height: 1.2;

                        &::before,
                        &::after {
                            top: 0;
                        }
                    }
                }
            }

            &.has-separators {

                > .form-group {
                    border-bottom: 1px solid $gray-300;

                    &:last-child {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .verification-row {
                margin: 0 0 4px;
                justify-content: space-between;
            }

            .input-authcode-send {

                .btn-authcode-send {
                    float: right
                }
            }

            .input-authcode-status {

                .input-group-text {
                    padding-top: 5px;
                    padding-bottom: 4px;
                }

                &:not(.input-group-append) {

                    .input-group-text {
                        padding: 0.25rem;
                        line-height: 0.5;
                    }
                }

                .status-text {
                    font-size: 0.75rem;

                    i {
                        font-size: 0.6rem;
                    }
                }
            }

            [class*="col"] + .input-authcode-status {
                margin-left: auto;
            }

            .input-authcode-send,
            .input-authcode-status,
            .input-authcode-input,
            .input-authcode-validate {
                font-size: 0.75rem;

                .input-group-text,
                .form-control,
                .btn {
                    font-size: inherit;

                    i {
                        font-size: 0.625rem;
                    }
                }

                .btn {
                    padding: 0.25rem;
                }
            }

            .input-authcode-validate {

                .btn-authcode-send,
                .btn-authcode-validate {
                    height: 32px;
                    padding: 0;
                    line-height: 1.1;
                }

                .btn-authcode-send {
                    width: 50%;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
                .btn-authcode-validate {
                    width: 50%;
                    border-radius: 0;
                    border-left: none;
                    border-right: none;
                }
            }

            .authcode-key-phone {
                height: 32px;
                max-width: calc(100% - 32px);
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            > .form-group {
                margin-top: .25rem;
                margin-bottom: .25rem;
            }

            .editable-generic-text {

                &-disabled {
                    text-decoration: none !important;
                    color: inherit !important;
                    cursor: default;
                }
            }
        }
    }


}

.form-row-double {
    margin-bottom: 1rem;
    border-bottom: 1px solid $gray-300;
}

.form-row-header {
    p {
        font-size: 1.15rem;
        margin-bottom: 0;
        color: $gray-600;
        line-height: 1;
    }

    padding-bottom: .5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $gray-300;
}

h4.h-with-check {
    display: flex;
    flex-direction: column;
    margin-bottom: .25rem;
    margin-top: .25rem;

    @media (min-width: map-get($grid-breakpoints, sm)) {
        flex-direction: row;
        align-items: center;

    }
}

.h-check {
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: .25rem;
    margin-bottom: .25rem;
    margin-left: 0;
    align-items: center;

    @media (min-width: map-get($grid-breakpoints, sm)) {
        margin-left: auto;
        flex-direction: row;
    }

    > span {
        margin-left: 1rem;
        text-align: left;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            margin-left: 0;
            margin-right: 1rem;
            text-align: right;
        }
    }

    input:disabled + span {
        opacity: .3;
        cursor: not-allowed;

        &:before {
            background: $cyan !important;
        }
    }

    .checkbox-slider input+span {
        padding-left: 15px;
    }
}

.form-group-check {
    .form-check {
        @media (min-width: map-get($grid-breakpoints, sm)) {
            margin-top: 2.25rem;
        }
    }
}


.form-section {

    &-customer-payment-invoices {

        &-disabled {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                opacity: 0.5;
            }
        }
    }

    &-customer-payment-management {
        padding-top: 0.5rem;
        border-top: 1px solid $gray-300;

        &:empty,
        &-success {
            padding-top: 0;
            border-top: none;
        }

        &-message {
            text-align: center;

            i {
                margin-top: 2rem;
                margin-bottom: 2rem;
                font-size: 5rem;
            }
        }
    }

    &-customer-payment-outcome {
        padding-top: 1rem;
        padding-bottom: 1rem;

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &-expanded {
        padding: .5rem;
        margin-bottom: .5rem;
        background-color: $gray-200;
        border-radius: 5px;
        box-shadow: 0px 0px 8px $gray-500 inset;
        position: relative;

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 16px 0 16px;
            border-color: $white transparent transparent transparent;
            position: absolute;
            left: 60px;
            top: -2px;
        }
    }

    &-bordered {
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
        margin-bottom: .5rem;
        padding: .5rem $grid-gutter-width/2;
        background-color: $gray-100;
        border-bottom: 1px solid $gray-200;
    }

    .topay-balance,
    .topay-amdue {

        i {
            width: 2.5rem;
        }
    }
}

// Form fields feedback
.feedback-container {
    font-size: .75rem;
}

// Editable
.input-group-editable {

    .input-group-append {
        position: relative;

        .btn {

            &-editable {

                &-clear {
                    position: absolute;
                    left: -44px;
                    top: -2px;
                    font-size: 1.25rem;
                    color: $gray-400;
                }
            }
        }
    }
}
// Editable Ends

div[class*="css-"][class*="-container"] {

    div[class$="-menu"] > div {
        max-height: 304px;
    }
}

// Disabled text field that is not greyed out
.form-group-appointment {
    position: relative;

    input:disabled {
        text-align: center;
        background: white;
    }

    &-clear {
        position: absolute;
        z-index: 1;
        top: 1px;
        right: 1px;
        bottom: 1px;
        width: 2.5rem;
        display: block;
        padding: 0;
        margin: 0;
        font-size: 2rem;
        line-height: 1;
        background: transparent;
        border: none;
        border-radius: 0 0.25rem 0.25rem 0;
        outline: none !important;

        &:hover {
            background: $gray-100;
        }
    }
}

// Add loading dots to input fields (like address autocomplete)

@keyframes input-loader {
    0%, 80%, 100% {opacity: 0;}
    40% {opacity: 1;}
}

.input-loader {
    position: relative;

    input {
        outline: none;
    }

    &-container {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(1.5em + 0.75rem + 2px);
        padding-right: 0.75rem;
    }

    &-dot {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: $gray-500;
        animation: 1s ease-in-out infinite input-loader;

        &:nth-child(2) {
            margin-left: 4px;
            animation-delay: 160ms;
        }

        &:nth-child(3) {
            margin-left: 4px;
            animation-delay: 320ms;
        }
    }
}

.custom-select {

    &:disabled {
        opacity: 1 !important;
    }
}