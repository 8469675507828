// Customer Status Bar
.status-bar {
    height: 16px;
    display: flex;
    justify-content: space-evenly;

    &-0 {

        // \2009 is thin space
        // \f111 is dot in FA5
        &:before {
            font-size: .7rem;
            font-weight: 900;
            content: "";
        }

        &:after {
            font-size: .7rem;
            font-weight: 400;
            content: "\f111\2009\f111\2009\f111\2009\f111\2009\f111";

        }
    }

    &-20 {

        // \2009 is thin space
        // \f111 is dot in FA5
        &:before {
            font-size: .7rem;
            font-weight: 900;
            content: "\f111\2009";
        }

        &:after {
            font-size: .7rem;
            font-weight: 400;
            content: "\f111\2009\f111\2009\f111\2009\f111";

        }
    }

    &-40 {

        // \2009 is thin space
        // \f111 is dot in FA5
        &:before {
            font-size: .7rem;
            font-weight: 900;
            content: "\f111\2009\f111\2009";
        }

        &:after {
            font-size: .7rem;
            font-weight: 400;
            content: "\f111\2009\f111\2009\f111";

        }
    }

    &-60 {

        // \2009 is thin space
        // \f111 is dot in FA5
        &:before {
            font-size: .7rem;
            font-weight: 900;
            content: "\f111\2009\f111\2009\f111\2009";
        }

        &:after {
            font-size: .7rem;
            font-weight: 400;
            content: "\f111\2009\f111";

        }
    }

    &-80 {

        // \2009 is thin space
        // \f111 is dot in FA5
        &:before {
            font-size: .7rem;
            font-weight: 900;
            content: "\f111\2009\f111\2009\f111\2009\f111\2009";
        }

        &:after {
            font-size: .7rem;
            font-weight: 400;
            content: "\f111";

        }
    }

    &-100 {

        // \2009 is thin space
        // \f111 is dot in FA5
        &:before {
            font-size: .7rem;
            font-weight: 900;
            content: "\f111\2009\f111\2009\f111\2009\f111\2009\f111\2009";
        }

        &:after {
            font-size: .7rem;
            font-weight: 400;
            content: "";

        }
    }
}

// Customer Status Bar Ends

// Customer Status Bar Colors Loop
@each $name,
$color in $theme-colors {
    .cmv-client-status-#{$name} {
        display: inline-flex;
        flex-direction: row-reverse;

        @media (min-width: map-get($grid-breakpoints, md)) {
            flex-direction: row;
        }

        align-items: center;
        height: 18px;

        .status-bar {

            &:before,
            &:after {
                color: $color;
                font-family: $icon-font;
            }
        }

        // Customer Status Bar Dropdown
        &.dropdown {
            .status-text {
                cursor: pointer;
                position: relative;
                border: 1px solid transparent;
                border-right-width: 0;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;

                &:after {
                    border: 1px solid transparent;
                    border-left-width: 0;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    padding-right: .2rem;
                }

                &:hover {
                    &:after {
                        position: absolute;
                        top: -1px;
                        left: calc(100% - 2px);
                        font-weight: 700;
                        font-family: $icon-font;
                        content: "\f0d7";
                    }
                }
            }
        }
        // Customer Status Bar Dropdown Ends

        .status-text {
            text-transform: uppercase;
            color: $color;
            font-weight: 700;
            font-size: .65rem;
            padding: 0 .2rem;
            margin-right: .2rem;
            margin-left: .2rem;

            &:hover {
                border-color: $color;

                &:after {
                    border-color: $color;
                }
            }
        }


    }

    .cmv-container-client-selected {
        .cmv-client-status-#{$name} {
            display: inline-flex;
            flex-direction: row-reverse;

            @media (min-width: map-get($grid-breakpoints, md)) {
                margin-bottom: .5rem;
            }
        }
    }
}

// Customer Status Bar Colors Loop Ends