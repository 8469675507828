.cmv-table {
    width: 100%;

    th {
        color: $gray-600;
        text-transform: uppercase;
        padding-top: .5rem;
        padding-bottom: .5rem;
        background-color: $white;
        user-select: none;
        outline: none;

        @media (max-width: map-get($grid-breakpoints, md) - 1) {
            font-size: 0.875rem;
        }
        @media (max-width: map-get($grid-breakpoints, sm) - 1) {
            font-size: 0.75rem;
        }
    }

    th.sortable {
        outline: none;
    }

    &:not(.cmv-table-solid) tbody {

        tr:nth-of-type(odd) {
            background-color: $gray-100;
        }

        tr.tr-expanded {
            background-color: darken($gray-100, 4%);
            position: relative;
        }
    }

    &-solid {

        .tr-dark {
            background-color: $gray-100;
        }

        .tr-border td {
            border-top-style: dashed;
        }

        .tr-grouped {
            border-left: 3px solid $accent-orders;
        }
    }

    tr.has-note {
        position: relative;
        transform: scale(1, 1);

        td {
            padding-bottom: 3rem;

            &.with-note {

                &:before {
                    content: "";
                    display: table-row;
                }
            }

            .td-note {
                font-size: .75rem;
                position: absolute;
                top: calc(100% - 3rem);
                left: 1rem;
                width: 95%;
                border-radius: $border-radius;
                // border: 1px solid $gray-300;
                // background-color: $gray-200;
                display: inline-block;
                padding: .25rem;
                padding-left: 2rem;
                color: $gray-700;

                &:before {
                    position: absolute;
                    left: 5px;
                    content: "\f05a";
                    color: $gray-500;
                    font-weight: 900;
                    font-family: $icon-font;
                }
            }
        }
    }

    td {
        padding-top: .375rem;
        padding-bottom: .375rem;

        >i {
            font-size: 1.375rem;
            line-height: 1.2;

            &.fa-money-check-alt {
                color: $secondary;
            }

            &.fa-credit-card {
                color: $primary;
            }

            &.fa-money-bill {
                color: $success;
            }
        }

        &.td-with-check {
            width: 1px;
            padding: .375rem;
            vertical-align: middle;

            .checkbox-slider {
                label {
                    margin-bottom: 0;
                }

                input+span {
                    display: inline;
                }
            }
        }

        &.reset-expansion-style {
            border-width: 0;
            padding: 0;
            background-color: lighten($gray-100, 2%);
            box-shadow: 0px 0px 8px $gray-500 inset;

            .row-expansion-style {
                padding: 0px;
            }
        }

        .td-subtitle {
            display: block;
            margin-bottom: 0.25rem;
            color: $gray-600;
            font-size: .75rem;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                margin-right: 0.25rem;
            }
            
            &-link {
                color: $primary;
                //text-decoration: underline;
            }
        }

        .tr-actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            button:disabled {
                color: darken($gray-100, 20%);
            }

            .btn {
                padding: 0.5rem;
                color: $gray-600;
                border-radius: 0;
                border-right: 1px solid $gray-400;
                cursor: pointer;

                i {
                    font-size: 1.625rem;
                    line-height: 1.2;
                }
            }

            > .btn:last-of-type {
                border: none;
            }
        }

        .badge {
            min-width: 6.25rem;
            padding: 0.25rem 0.5rem;
            margin-bottom: 0.25rem;
            text-transform: uppercase;
            font-size: .875rem;

            &-inline {
                min-width: 0;
                margin-right: 0.25rem;
            }
        }

    }

    .v-align-m {
        vertical-align: middle;
    }

    .h-align-r {
        text-align: right;
    }

    // Mobile Tables
    &-mobile {

        @media (max-width: map-get($grid-breakpoints, sm) - 1) {

            table, thead, tbody, th, td, tr {
                display: block;
            }

            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tbody tr:nth-of-type(odd) {
                background-color: $gray-200;
            }

            td {
                position: relative;
                min-height: 2.25rem;
                padding: 0.25rem 0.5rem 0.25rem 30%;
                border-top-color: $gray-400;

                &:empty {
                    display: none;
                }

                &::before {
                    content: attr(data-heading);
                    position: absolute;
                    top: 0.25rem;
                    left: 0;
                    width: 30%;
                    padding: 0 0.25rem 0 0.5rem;
                    color: $gray-600;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 0.75rem;
                    line-height: 1.5rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .tr-actions {
                    justify-content: flex-start;

                    .dropdown {
                        position: static;
                    }

                    .dropdown-menu {
                        top: calc(100% - 0.5rem);
                        left: 0.5rem;
                        right: 0.5rem;
                    }
                }
            }

            tbody tr:last-child td .tr-actions .dropdown-menu {
                top: auto;
                bottom: calc(100% - 0.5rem);
            }
        }

        @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {

            td .tr-actions {
                flex-direction: column;

                .btn {

                    width: 100%;
                    padding-left: 0.5rem;
                    padding-right: 0;
                    text-align: left;
                    border: none;
                    border-bottom: 1px solid $gray-400;

                    &.dropdown-toggle {
                        padding-left: 0.75rem;
                    }
                }
            }
        }

        @media (min-width: map-get($grid-breakpoints, sm)) {

            th {

                &:last-child {
                    text-align: right;
                }
            }

            tbody tr:last-child:not(:first-child):not(:nth-child(2)) td .tr-actions .dropdown-menu {
                top: auto;
                bottom: 100%;
            }
        }

        @media (min-width: map-get($grid-breakpoints, md)) {

            tbody tr:nth-last-child(2):not(:first-child) td .tr-actions .dropdown-menu {
                top: auto;
                bottom: 100%;
            }
        }
    }

    &-capacity {

        th {

            @media (max-width: map-get($grid-breakpoints, xl) - 1) {
                font-size: 0.75rem;

                &:first-child {
                    text-align: right;
                }
            }
        }

        td {

            &:not(:first-child) {
                padding: 0.375rem 0.1875rem;
            }

            &:first-child {
                font-size: 0.75rem;

                span {
                    display: block;
                    white-space: nowrap;
                    text-align: right;
                }

                @media (min-width: map-get($grid-breakpoints, xl)) {
                    font-size: 0.875rem;
                    white-space: nowrap;

                    span {
                        display: inline-block;
                        line-height: 2.75;
                        text-align: right;

                        &:last-child {

                            &::before {
                                content: "-";
                                margin: 0 0.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table-tools {
    margin-bottom: .5rem;
}