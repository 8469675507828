$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

//Colors
$black: #000;
$white: #fff;
$white-darker: #F5F7F9;

$sidebar-bg: #3C4A9C;
$alert-bg-warning: #DFA416;

$accent-customers: #009ACE;
$accent-account: $accent-customers;
$accent-tasks: #D60458;
$accent-appointments: #FFC000;
$accent-orders: #3C4A9C;
$accent-tickets: #12A141;
$accent-lock: $alert-bg-warning;

$main: $primary;
$cmv-upsale-accent: $purple !default;
$cmv-bs-container-max-width: 1940px;

$light-gray: #f7f7f7;
$gray: #eee;
$mid-gray: #d6d6d6; //#e1e1e1;
$dark-gray: #999;
$extra-dark-gray: #666;

$data: #e2e3e5;

$dark-green: #00692c;
$dark-red: #990000;

$white-transparent: rgba( 255, 255, 255, .5);

// Breakout container width
$breakout-container-max-width:	1900px !default;

//Base Height of element
$h-45: 45px;

//Base Padding
$p-10: 10px;
$p-15: 15px;
$p-20: 20px;
$p-25: 25px;
$p-30: 30px;

//Margins
$m-5: 5px;
$m-10: 10px;
$m-15: 15px;
$m-20: 20px;
$m-25: 25px;
$m-30: 30px;

//Font
$main-font: 'Fira Sans', sans-serif;
$main-font-condensed: 'Fira Sans Condensed', sans-serif;
$icon-font: "Font Awesome\ 5 Free";

// Brand
$brand-string: "camvio ";

$f-thin: 100;
$f-light: 300;
$f-regular: 400;
$f-medium: 500;
$f-bold: 700;
$f-black: 900;

$f-10: 10px;
$f-12: 12px;
$f-14: 14px;


$status-percentages: () !default;
$status-percentages: map-merge(
  (
    "0":	   0%,
    "20":	  20%,
    "40":     40%,
    "60":	  60%,
    "80":	  80%,
    "100":	 100%
  ),
  $status-percentages
);