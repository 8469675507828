// Camv.io Top Navbar
.cmv-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.75rem;
    padding: 0;
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    @media (min-width: map-get($grid-breakpoints, md)) {
        height: 3.75rem;
    }
    @media (min-width: 1920px) {
        height: 5rem;
    }

    &.cmv-navbar-customers,
    &.cmv-navbar-account,
    &.cmv-navbar-tasks,
    &.cmv-navbar-orders,
    &.cmv-navbar-tickets,
    &.cmv-navbar-lock {
        color: $white;

        .btn-circle {
            background: $white;
            border: 2px solid $white;

            &:hover {
                color: $white;
            }
        }
    }

    &.cmv-navbar-customers {background: $accent-customers;
        .nav-link-disabled i {color: $accent-customers;}
        .btn-circle {color: $accent-customers;
            &:hover {background: $accent-customers;}}
    }
    &.cmv-navbar-account {background: $accent-account;
        .nav-link-disabled i {color: $accent-account;}
        .btn-circle {color: $accent-account;
            &:hover {background: $accent-account;}}
    }
    &.cmv-navbar-tasks {background: $accent-tasks;
        .nav-link-disabled i {color: $accent-tasks;}
        .btn-circle {color: $accent-tasks;
            &:hover {background: $accent-tasks;}}
    }
    &.cmv-navbar-orders {background: $accent-orders;
        .nav-link-disabled i {color: $accent-orders;}
        .btn-circle {color: $accent-orders;
            &:hover {background: $accent-orders;}}
    }
    &.cmv-navbar-tickets {background: $accent-tickets;
        .nav-link-disabled i {color: $accent-tickets;}
        .btn-circle {color: $accent-tickets;
            &:hover {background: $accent-tickets;}}
    }
    &.cmv-navbar-lock {background: $accent-lock;
        .nav-link-disabled i {color: $accent-lock;}
        .btn-circle {color: $accent-lock;
            &:hover {background: $accent-lock;}}
    }
    &.cmv-navbar-report {
        .nav-link-disabled {border-left-color: $gray-300 !important;}
        .nav-link-disabled i {background: $white-darker !important;}
    }

    .navbar-logo {
        display: none;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            display: block;
            width: 260px;
            height: 100%;
            padding: 0.5rem 1rem;
            text-align: center;

            img  {
                max-width: 100%;
                max-height: 2.5rem;
            }
        }
        @media (min-width: 1920px) {
            width: 320px;
            padding: 1rem;
        }
    }

    .navbar-nav {
        align-self: stretch;

        .nav-item {
            display: flex;
            padding: 0;
        }

        .nav-item:last-child .nav-link {

            @media (min-width: map-get($grid-breakpoints, xl)) and (max-width: 1919px) {
                padding-right: 2rem !important;
            }
        }

        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: inherit;
            border-left: 1px solid $gray-300;
            outline: none;
            transition: background .1s linear;

            @media (max-width: map-get($grid-breakpoints, md) - 1) {
                width: 2.75rem;
            }
            @media (min-width: map-get($grid-breakpoints, md)) {
                padding: 0 1rem;
            }
            @media (min-width: map-get($grid-breakpoints, xl)) {
                padding: 0 1.5rem;
            }
            @media (min-width: 1920px) {
                padding: 0 2rem;
            }

            i {
                transition: background .1s linear;

                @media (min-width: map-get($grid-breakpoints, md)) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 0.5rem;
                    background: $white-darker;
                    background: rgba(0, 0, 0, 0.03);
                    border-radius: 100px;
                }
            }

            &:not(.nav-link-disabled):hover {
                background: $white-darker;

                i {

                    @media (min-width: map-get($grid-breakpoints, md)) {
                        background: $white;
                    }
                }
            }
            
            &-disabled {
                border-left-color: rgba(255, 255, 255, 0.5);

                @media (min-width: map-get($grid-breakpoints, md)) {
                    opacity: 0.7;
                    cursor: not-allowed;
                }

                i {

                    @media (max-width: map-get($grid-breakpoints, md) - 1) {
                        color: $white;
                    }
                    @media (min-width: map-get($grid-breakpoints, md)) {
                        background: $white;
                    }
                }

                + .dropdown-menu {

                    @media (min-width: map-get($grid-breakpoints, md)) {
                        display: none;
                    }
                }
            }

            &-caption {
                line-height: 1;

                @media (max-width: map-get($grid-breakpoints, md) - 1) {
                    display: none;
                }
            }

            &-title {
                display: block;
                font-size: 1rem;
                font-weight: 500;
            }

            &-text {
                font-size: 0.75rem;
                opacity: 0.6;
            }
        }
    }

    .btn-circle,
    .navbar-text {
        margin: 0 0 0 0.5rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-left: 1rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin-left: 2rem;
        }
    }

    .btn-circle {
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        color: inherit;
        background: rgba(0, 0, 0, 0.03);
        border: none;

        @media (min-width: map-get($grid-breakpoints, md)) {
            width: 2.5rem;
            height: 2.5rem;
        }

        i {
            line-height: inherit;
        }
    }

    .btn-toggle-sidebar {

        @media (min-width: map-get($grid-breakpoints, lg)) {
            display: none;
        }
    }

    .navbar-text {
        padding: 0.125rem 0 0 0;
        margin-right: auto;
        font-weight: 500;
        font-size: .75rem;
        white-space: nowrap;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 1rem;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            font-size: 1rem;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 1.25rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            font-size: 1.5rem;
            letter-spacing: 1px;
        }
    }

    .navbar-userinfo {
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        max-width: 100px;

        @media (min-width: map-get($grid-breakpoints, sm)) {
            max-width: 200px;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            max-width: unset;
        }
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        margin-top: -0.25rem;
        border: none;
        box-shadow: 0 0 5px rgba(0, 0, 0, .25);

        @media (min-width: map-get($grid-breakpoints, md)) {

            .dropdown-header,
            .dropdown-divider {
                display: none;
            }
        }
    }
}